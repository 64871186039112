
import OSS from 'ali-oss';
import overflowTxt from '../../components/overflowTxt.vue';
export default {
    components: { overflowTxt },
    data() {
        return {
            resetOssNum: 0,
            downloadLoading: false,
            queryLoading: false,
            editableTabsValue: '1',
            editableTabs: [
                {
                    title: '正式案件',
                    menuTypeNum: '1'
                },
                {
                    title: '预报案案件',
                    menuTypeNum: '2'
                },
                {
                    title: '风险统计',
                    menuTypeNum: '3'
                },
                {
                    title: '预报案转化清单',
                    menuTypeNum: '4'
                }
            ],
            navForm: {
                condition: {
                    claimAmountRange: null,
                    closeAmountRange: null,
                    caseInflowTimeStart: null,
                    caseInflowTimeEnd: null,
                    caseStep: null,
                    sendTimeStart: null,
                    sendTimeEnd: null,
                    policyStartTimeStart: null,
                    policyStartTimeEnd: null,
                    accidentReason: null,
                    goodsCategory: null,
                    lossReductionReasonType: null,
                    lossAssessmentReasonType: null
                },
                pageNo: 1,
                pageSize: 10
            },
            completeObj: {},
            caseStepOption: [],
            userTableData: [],
            reasonOption: [],
            accCauseOption: [
                {
                    code: 10,
                    desc: '丢失'
                },
                {
                    code: 20,
                    desc: '破损'
                }
            ],
            amountOption: [],
            goodsCategoryOption: [],
            tableHeight: null,
            impairmentReasonOption: [],
            downloadForm: null,
            tmpOSSClient: null,
            fileFLag: false,
            fileTime: 0,
            searchFold: false,
            statisticsLoading: true,
            statisticsObj: {},
            statisticsData: [],
            statisticsForm: {
                condition: {},
                pageNo: 1,
                pageSize: 10,
                pageTotal: 0
            },
            convertLoading: true,
            convertObj: {},
            convertData: [],
            convertForm: {
                condition: {},
                pageNo: 1,
                pageSize: 20,
                pageTotal: 0
            }
        };
    },
    mounted() {
        this.initOSSClient();
        // 动态计算table高度
        this.calcHeight();
        window.onresize = () => {
            this.calcHeight();
        };
        this.clickTab();
        // 金额区间
        this.getAmount();
        // 商品类别
        this.getGoodsCategoryEnum();
        // 定损原因
        this.getGoBackLossDiffEnum();
        // 减损原因
        this.getImpairmentRenson();
    },
    methods: {
        foldQuery() {
            this.searchFold = !this.searchFold;
            this.$nextTick(() => {
                this.calcHeight(true);
            });
        },
        // 风险清单
        getStatistics() {
            this.statisticsObj = {};
            this.instance.post('/tpa/api/dashboard/case/risk/statistics', this.statisticsForm).then((res) => {
                this.statisticsLoading = false;
                let data = res.data;
                if (data.code == 200) {
                    this.statisticsObj = data.data;
                    this.statisticsForm.pageTotal = data.data.data.total;
                }
            });
        },
        // 预报案转化清单
        getConvert() {
            this.convertObj = {};
            this.instance.post('/tpa/api/dashboard/pre-case/convert', this.convertForm).then((res) => {
                this.convertLoading = false;
                let data = res.data;
                if (data.code == 200) {
                    this.convertObj = data.data;
                    this.convertForm.pageTotal = data.data.data.total;
                }
            });
        },
        getReportCaseStepEnum() {
            this.instance.get('/tpa/api/enum/getReportCaseStepEnum', {}).then((res) => {
                if (res.data.code == 200) {
                    this.caseStepOption = res.data.data;
                }
            });
        },
        getPreCaseStepEnum() {
            this.instance.get('/tpa/api/enum/getPreCaseStepEnum', {}).then((res) => {
                if (res.data.code == 200) {
                    this.caseStepOption = res.data.data;
                }
            });
        },
        // 初始化OSS
        initOSSClient() {
            this.tmpOSSClient = null;
            this.resetOssNum++;
            this.instance
                .get('/tpa/api/aliyun/oss/access')
                .then((res) => {
                    let data = res.data;
                    if (data.code == 200) {
                        this.tmpOSSClient = new OSS({
                            accessKeyId: data.data.accessKeyId,
                            accessKeySecret: data.data.accessKeySecret,
                            stsToken: data.data.securityToken,
                            // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou。
                            region: data.data.region,
                            bucket: data.data.bucket
                        });
                    } else {
                        console.log('oss初始化失败，正在重试！');

                        if (this.resetOssNum < 20) {
                            setTimeout(() => {
                                this.initOSSClient();
                            }, 1000);
                        } else {
                            console.log('oss初始化超过20s，失败!');
                        }
                    }
                })
                .catch((err) => {
                    console.log('oss初始化失败-');
                    console.log(err);
                });
        },
        async isExistObject(name, options = {}) {
            try {
                await this.tmpOSSClient.head(name, options);
                console.log('文件存在');
                this.fileFLag = true;
            } catch (error) {
                if (error.code === 'NoSuchKey') {
                    console.log('文件不存在');
                    this.fileFLag = false;
                } else {
                    console.log('error:');
                    console.log(error);
                }
            }
        },
        calcHeight(foldHeight) {
            let elRadioGroup = document.querySelector('.el-radio-group') ? document.querySelector('.el-radio-group').clientHeight : 0;
            // 顶部tabs
            let registerTabsHeight = document.querySelector('.register_tabs') ? document.querySelector('.register_tabs').clientHeight : 0;
            // 搜索筛选项
            let publicBoxHeight = document.querySelector('.public_box') ? document.querySelector('.public_box').clientHeight : 0;
            // 分页
            let elPaginationHeight = document.querySelector('.el-pagination') ? document.querySelector('.el-pagination').clientHeight : 0;
            let tableHeaderHeight = 0;
            let guHeight = null;

            if (this.searchFold) {
                guHeight = 24 + 20 + 60 + 55 - 2;
            } else {
                if (foldHeight) {
                    guHeight = 24 + 20 + 60 + 55 - 2;
                } else {
                    guHeight = 24 + 20 + 60 + 55 + 30;
                }
            }

            this.tableHeight = window.innerHeight - registerTabsHeight - publicBoxHeight - elPaginationHeight - tableHeaderHeight - guHeight;

            this.tableHeight = this.tableHeight < 200 ? 200 : this.tableHeight;
        },
        getAmount() {
            this.instance.get('/tpa/api/dashboard/enum/amount').then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    this.amountOption = data.data;
                }
            });
        },
        getGoodsCategoryEnum() {
            this.instance.post('/tpa/api/enum/getGoodsCategoryEnum', {}).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    this.goodsCategoryOption = data.data;
                }
            });
        },
        getImpairmentRenson() {
            this.instance.post('/tpa/api/enum/getAdjustEnum').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.impairmentReasonOption = data.data;
                    this.impairmentReasonOptionObj = {};
                    data.data.forEach((item) => {
                        this.impairmentReasonOptionObj[item.code] = item.desc;
                    });
                }
            });
        },
        getGoBackLossDiffEnum() {
            this.instance.post('/tpa/api/enum/getGoBackLossDiffEnum').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.reasonOption = data.data;
                }
            });
        },
        getFormalCaseData() {
            this.completeObj = {};
            this.queryLoading = true;
            this.instance.post('/tpa/api/dashboard/case/list/paging', this.navForm).then((res) => {
                this.queryLoading = false;
                let data = res.data;
                if (data.code == 200) {
                    this.downloadForm = JSON.parse(JSON.stringify(this.navForm));
                    this.completeObj = data.data;
                    this.navForm.pageTotal = data.data.data.total;
                }
            });
        },
        getForecastCaseData() {
            this.completeObj = {};
            this.queryLoading = true;
            this.instance.post('/tpa/api/dashboard/pre-case/list/paging', this.navForm).then((res) => {
                this.queryLoading = false;
                let data = res.data;
                if (data.code == 200) {
                    this.downloadForm = JSON.parse(JSON.stringify(this.navForm));
                    this.completeObj = data.data;
                    this.navForm.pageTotal = data.data.data.total;
                }
            });
        },
        downloadFormalCaseData() {
            this.fileFLag = false;
            this.downloadLoading = true;
            this.instance.post('/tpa/api/dashboard/case/list/download', this.downloadForm).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.downloadFile(data.data);
                }
            });
        },
        downloadForecastCaseData() {
            this.fileFLag = false;
            this.downloadLoading = true;
            this.instance.post('/tpa/api/dashboard/pre-case/list/download', this.downloadForm).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.downloadFile(data.data);
                }
            });
        },
        downloadFile(fileKey) {
            if (this.tmpOSSClient) {
                this.isExistObject(fileKey);
                // 400*3000=1200s=20m
                if (this.fileTime > 400) {
                    this.$message.error('文件下载失败，请稍后重试！');
                    this.downloadLoading = false;
                    return;
                }

                if (!this.fileFLag) {
                    setTimeout(() => {
                        this.fileTime++;
                        this.downloadFile(fileKey);
                    }, 3000);
                } else {
                    this.fileFLag = false;
                    let fileUrl = this.tmpOSSClient.signatureUrl(fileKey);

                    // window.open(fileUrl);
                    setTimeout(() => {
                        this.downloadLoading = false;
                        location.href = fileUrl;
                    }, 1000);
                }
            }
        },
        //重置筛选项
        resetForm() {
            for (const key in this.navForm.condition) {
                this.navForm.condition[key] = null;
            }
            this.navForm.pageNo = 1;
            this.navForm.pageSize = 10;
            this.$forceUpdate();
        },
        async clickTab(downloadFlag, e, queryFlag) {
            setTimeout(() => {
                this.calcHeight();
            }, 100);
            await this.initOSSClient();
            if (String(downloadFlag) == 'true') {
                this.fileTime = 0;
                switch (this.editableTabsValue) {
                    case '1':
                        this.downloadFormalCaseData();
                        break;
                    case '2':
                        this.downloadForecastCaseData();
                        break;

                    default:
                        break;
                }
            } else {
                if (String(downloadFlag) == 'false') {
                    this.navForm.pageNo = 1;
                }
                if (!queryFlag) {
                    this.resetForm();
                }
                switch (this.editableTabsValue) {
                    case '1':
                        this.getFormalCaseData();
                        this.getReportCaseStepEnum();
                        break;
                    case '2':
                        this.getForecastCaseData();
                        this.getPreCaseStepEnum();
                        break;
                    case '3':
                        this.getStatistics();
                        break;
                    case '4':
                        this.getConvert();
                        break;

                    default:
                        break;
                }
            }
        },
        handleCurrentChange(val) {
            this.navForm.pageNo = val;
            this.clickTab(null, null, true);
        },
        handleSizeChange(val) {
            this.navForm.pageSize = val;
            this.clickTab(null, null, true);
        },
        inventoryCurrentChange(typeNum, val) {
            if (typeNum == '1') {
                this.statisticsForm.pageNo = val;
            } else {
                this.convertForm.pageNo = val;
            }
            this.clickTab(null, null, true);
        },
        inventorySizeChange(typeNum, val) {
            if (typeNum == '1') {
                this.statisticsForm.pageSize = val;
            } else {
                this.convertForm.pageSize = val;
            }
            this.clickTab(null, null, true);
        },
        containsChinese(str) {
            const regex = /[\u4e00-\u9fff]+/g;
            return regex.test(str);
        },
        inventoryDownload() {
            this.downloadLoading = true;
            let downloadUrl = this.editableTabsValue == '3' ? '/tpa/api/dashboard/case/risk/statistics/download' : '/tpa/api/dashboard/pre-case/convert/download';
            this.instance.post(downloadUrl, {}).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.downloadFile(data.data);
                }
            });
        }
    }
};


import Claimant from './Claimant.vue';
import ClaimantCopy from './ClaimantCopy.vue';
import OSS from 'ali-oss';
import { v4 as uuidv4 } from 'uuid';
import ImgDeal from '@/components/ImgDeal.vue';
import PreviewRiskPopup from './toolBox/previewRiskPopup.vue';
let cascCaderOptions = [];
let _this = null;
export default {
    name: 'LossAssessment',
    components: {
        ImgDeal,
        Claimant,
        ClaimantCopy,
        PreviewRiskPopup
    },
    props: {
        caseDetail: {
            typeof: Object,
            default: {}
        },
        suspensionBtnObj: {
            typeof: Object,
            default: {}
        },
        tmpOSSClient: {
            type: Object,
            default: null
        },
        elementShow: {
            typeof: Boolean,
            default: false
        }
    },
    data() {
        return {
            resetOssNum: 0,
            tmpLossAmount: null,
            tmpGoodsTotalAmount: null,
            tmpShippingFee: null,
            adjustedTotalAmount: null,
            switchFlag: false,
            reasonPopupFlag: false,
            showPopup: false,
            isFold1: true,
            isFold2: true,
            isFold3: true,
            isFold4: true,
            isFold5: true,
            previewShow: false,
            goodsModeForm: {
                processMethod: 10,
                otherExpenses: []
            },
            baseURL: '',
            unloaderHeader: { token: '' }, //上传的请求头
            fileList: [],
            damagedProcessMethod: [
                { code: 10, desc: '赔偿' },
                { code: 20, desc: '维修' }
            ],
            damagedDegreeOption: [],
            otherSelectOption: [],
            adjustResult: [],
            maintenanceRuleForm: {
                accessoriesAmount: 0,
                repairAmount: 0,
                deductibleRatio: 0,
                shippingFee: 0,
                confirmAmount: ''
            },
            OSSClient: null,
            maintenanceRules: {
                accessoriesAmount: [{ required: true, message: '请输入配件费用', trigger: 'blur' }],
                deductibleRatio: [{ required: true, message: '请输入免赔率', trigger: 'blur' }],
                repairAmount: [{ required: true, message: '请输入人工费用', trigger: 'blur' }],
                shippingFee: [{ required: true, message: '请输入运费', trigger: 'blur' }]
                // confirmAmount: [{ required: true, message: '请输入赔偿金额', trigger: 'blur' }]
            },
            productVisible: false,
            errorVisible: false,
            riskErrorList: [],
            allPriceTableData: [],
            allPriceTableData1: [],
            allPriceTableData2: [],
            categoryTreeOption: [],
            firstCategoryOption: [],
            addGoodsCategoryForm: {
                name: '',
                parentId: ''
            },
            dialogVisibleAddGoodsCategory: false,
            dialogVisibleAddGoods: false,
            addGoodsForm: {},
            mallOption: [],
            tmpProductItem: {
                row: {},
                rowIndex: 0
            },
            multipleSelection: [],
            fileTypeList: [],
            isEditInfo: false,
            materialTypeForm: {
                code: ''
            },
            innerVisible: false,
            highRiskInfos: [],
            categoryRules: {
                parentId: [{ required: true, message: '请选择商品类目', trigger: 'change' }],
                name: [{ required: true, message: '请填写商品名称', trigger: 'blur' }]
            },
            commodityRules: {
                goodsName: [{ required: true, message: '请填写商品名称', trigger: 'blur' }],
                price: [{ required: true, message: '请填写商品名称', trigger: 'blur' }],
                shopName: [{ message: '请填写商品名称', trigger: 'blur' }],
                goodsCategoryId: [{ required: true, message: '请选择商品类目', trigger: 'change' }],
                mall: [{ required: true, message: '请选择商品类目', trigger: 'change' }]
            },
            queryGoodsForm: {
                condition: '',
                pageNo: 1,
                pageSize: 10,
                total: 0
            },
            activeNameArray: [],
            editConfirmAmount: false,
            tmpConfirmAmount: null,
            editTotalAmount: false,
            tmpTotalObj: {},
            materialsList: [],
            forms: {
                amount: ''
            },
            num: '',
            rendertable: false,
            goodsVisible: false,
            carVisible: false,
            priceRableLoading: true,
            priceRableLoading1: true,
            priceRableLoading2: true,
            riskNumData: {
                num: 0
            },
            proupType: null,
            medicineProup: false,
            medicineOutboundOcrProup: false,
            medicineForm: {
                condition: {
                    isCalibration: 1,
                    name: ''
                },
                total: 0,
                pageNo: 1,
                pageSize: 10
            },
            medicineList: [],
            addMedicineForm: {
                medicineName: '',
                medicineProperty: '',
                approvalNumber: '',
                manufacturer: '',
                medicineType: '',
                specification: '',
                price: ''
            },
            medicinePropertyOptions: [],
            medicineTypeOptions: [],
            medicineRules: {
                medicineName: [{ required: true, message: '请输入药品名称', trigger: ['blur', 'change'] }],
                medicineProperty: [{ required: true, message: '请输入药品性质', trigger: ['blur', 'change'] }]
            },
            imgGroup: {},
            imgGroupList: [],
            imgUrlList: [],
            thumbnailActiveMaterialParentTypeName: '',
            currentActiveId: 0,
            currentActiveMaterial: {},
            initialIndex: 0,
            ocrRecognitionLoading: false,
            ocrResult: [],
            tabActiveMaterialsId: 0,

            calibratedOcrResult: [],
            calibratedOcrResultImgs: [],
            originOcrResult: [],

            medicineRepositoryProup: false,
            medicineRepositoryData: {},
            medicineRepositoryLoading: false,
            tabActiveMaterialsLoading: false,
            selectedRow: null,
            parentSelectedRow: null,
            selectedRows: [],
            differentColorColumns: [],
            goodsCategory: '',
            // 已经识别过的出库单列表
            alreadyOcrList: [],
            previewRiskData: [],
            lossPageSetEditData: null,
            reduceReasonScript: [],
            goodsNum: 0,
            tabPosition: 1,
            pathStr: '',
            carPartFlag: false,
            cascCaderProps: {
                lazy: true,
                lazyLoad(node, resolve) {
                    const { level } = node;

                    if (level <= 1) {
                        resolve(cascCaderOptions);
                    } else {
                        _this.getSeriesInfo(node.value).then((res) => {
                            resolve(res);
                        });
                    }
                }
            },
            yearLoading: false,
            selectedParts: [],
            yearOptions: [],
            searchCarForm: {
                cascader: '',
                year: ''
            },
            resultCarList: [],
            queryCarInfoFlag: true,
            searchInfoForm: {
                amVehicleId: null,
                partGroupId: null,
                oeOrPartName: null,
                queryType: null
            },
            structureList: [],
            carPartList: [],
            provinceObj: {
                // marketPriceHn: ['福建', '江西', '湖北', '湖南', '广东', '深圳', '广西', '海南', '贵州'],
                // marketPriceHd: ['上海', '江苏', '浙江', '安徽'],
                // marketPriceHb: ['北京', '天津', '河北', '山西', '内蒙古'],
                // marketPriceDb: ['辽宁', '吉林', '黑龙江'],
                // marketPriceXb: ['重庆', '四川', '云南', '西藏', '陕西', '甘肃', '青海', '宁夏', '新疆']
            },
            provinceOptions: [
                {
                    label: '北京',
                    value: 'marketPriceHb'
                },
                {
                    label: '天津',
                    value: 'marketPriceHb'
                },
                {
                    label: '河北',
                    value: 'marketPriceHb'
                },
                {
                    label: '山西',
                    value: 'marketPriceHb'
                },
                {
                    label: '内蒙古',
                    value: 'marketPriceHb'
                },
                {
                    label: '辽宁',
                    value: 'marketPriceDb'
                },
                {
                    label: '吉林',
                    value: 'marketPriceDb'
                },
                {
                    label: '黑龙江',
                    value: 'marketPriceDb'
                },
                {
                    label: '上海',
                    value: 'marketPriceHd'
                },
                {
                    label: '江苏',
                    value: 'marketPriceHd'
                },
                {
                    label: '浙江',
                    value: 'marketPriceHd'
                },
                {
                    label: '安徽',
                    value: 'marketPriceHd'
                },
                {
                    label: '重庆',
                    value: 'marketPriceXb'
                },
                {
                    label: '四川',
                    value: 'marketPriceXb'
                },
                {
                    label: '云南',
                    value: 'marketPriceXb'
                },
                {
                    label: '西藏',
                    value: 'marketPriceXb'
                },
                {
                    label: '陕西',
                    value: 'marketPriceXb'
                },
                {
                    label: '甘肃',
                    value: 'marketPriceXb'
                },
                {
                    label: '青海',
                    value: 'marketPriceXb'
                },
                {
                    label: '宁夏',
                    value: 'marketPriceXb'
                },
                {
                    label: '新疆',
                    value: 'marketPriceXb'
                },
                {
                    label: '福建',
                    value: 'marketPriceHn'
                },
                {
                    label: '江西',
                    value: 'marketPriceHn'
                },
                {
                    label: '湖北',
                    value: 'marketPriceHn'
                },
                {
                    label: '湖南',
                    value: 'marketPriceHn'
                },
                {
                    label: '广东',
                    value: 'marketPriceHn'
                },
                {
                    label: '深圳',
                    value: 'marketPriceHn'
                },
                {
                    label: '广西',
                    value: 'marketPriceHn'
                },
                {
                    label: '海南',
                    value: 'marketPriceHn'
                },
                {
                    label: '贵州',
                    value: 'marketPriceHn'
                }
            ],
            provinceVal: null,
            partPrice: null,
            activeMenuStr: '',
            tmpMenuStr: '',
            selectTableObj: {},
            carPartLoading: false
        };
    },
    mounted() {
        _this = this;
        this.pathStr = this.$route.path;
        this.baseURL = this.$store.getters.baseURL;
        // 获取其他费用
        this.getOtherSelect();
        // 获取受损程度
        this.getDamagedDegreeEnum();
        // 获取商品品类树option
        this.getCategoryTree();
        // 获取商品品类第一级
        this.getFirstCategory();
        //获取商城枚举
        this.getPublicEnum('getMallEnum', 'mallOption');
        // 获取药品性质
        this.getMedicineProperty();
        // 获取药品分类
        this.getMedicineType();
        // 获取车辆品牌
        this.getBrandInfo();
    },
    methods: {
        enterTxt(item, index, val, valStr) {
            if (this.isEditInfo) {
                let tmpList = JSON.parse(JSON.stringify(this.caseDetail.goodsDtoList));

                tmpList[0][valStr] = val;
                item[valStr] = val;

                this.caseDetail.goodsDtoList = tmpList;
                this.$forceUpdate();
            }
        },
        closeRiskDialog(flag) {
            this.previewShow = flag;
        },
        getCaseDetail() {
            this.$emit('handleCaseList', this.caseDetail.caseNo);
        },
        // 点击出库单识别弹出药品图片OCR
        medicineOutboundOcr() {
            if (this.imgUrlList && this.imgUrlList.length === 0) {
                this.processImgList(this.caseDetail.caseNo);
                this.processOutboundCalibratedOcr(this.caseDetail.caseNo);
                this.processOutboundOriginOcr(this.caseDetail.caseNo);
            }
            this.processOutboundOriginOcr(this.caseDetail.caseNo);
            this.medicineOutboundOcrProup = true;
        },

        //索赔资料处照片删除的回调
        removeFileList(index, imgIndex) {
            this.goodsModeForm.otherExpenses[index].expenseImages.splice(imgIndex, 1);
            this.saveOtherExpense();
        },
        //索赔资料处照片点击文件列表中已上传的文件时的钩子
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisibleBigImg = true;
            console.log('点击文件列表中已上传的文件时的钩子');
            console.log(file.url);
        },
        //文件上传成功时的钩子
        handleAvatarSuccess(res, file) {
            console.log('上传成功的钩子');
            console.log(res);
            console.log(file);
            // this.fullscreenLoading.close()
        },
        //上传文件之前的钩子
        beforeAvatarUpload(file) {
            if (file.size / 1024 > 5000) {
                this.$message.error('抱歉，上传文件不得大于5M！');
                return false;
            }
            // this.fullscreenLoading = this.$loading({
            //     lock: true,
            //     text: '上传中，请稍后',
            //     spinner: 'el-icon-loading',
            //     background: 'rgba(0, 0, 0, 0.7)'
            // })
        },
        //OSS直传
        ossUpload(e, rowItem, rowIndex) {
            var _this = this;
            const OSS = require('ali-oss');
            let file = e.file;
            let storeAs = 'upload-file';
            let protocol = location.protocol;
            // let tmpRowItem = JSON.parse(JSON.stringify(rowItem))

            // OSS.urllib是SDK内部封装的发送请求的逻辑，开发者可以使用任何发送请求的库向sts-server发送请求。
            OSS.urllib.request(protocol + _this.baseURL + '/tpa/api/aliyun/oss/access', { method: 'GET' }, (err, response) => {
                try {
                    var result = JSON.parse(response).data;
                } catch (e) {
                    console.log('parse sts response info error: ' + e.message);
                }
                let client = new OSS({
                    accessKeyId: result.accessKeyId,
                    accessKeySecret: result.accessKeySecret,
                    stsToken: result.securityToken,
                    // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou。
                    region: result.region,
                    bucket: result.bucket
                });
                // storeAs可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
                // file可以自定义为File对象、Blob数据以及OSS Buffer。
                storeAs = result.path + '/' + uuidv4() + '.' + file.name.split('.')[1];
                setTimeout(() => {
                    client
                        .multipartUpload(storeAs, file)
                        .then(function (result) {
                            console.log('上传成功');
                            // storeAs - 阿里返回图片路径
                            if (rowItem.expenseImages) {
                                rowItem.expenseImages.push(storeAs);
                                let tmpRowItem = JSON.parse(JSON.stringify(rowItem));
                                _this.$set(_this.goodsModeForm.otherExpenses, rowIndex, tmpRowItem);
                                _this.saveOtherExpense();
                            }
                        })
                        .catch(function (err) {
                            console.log(err);
                        });
                }, 500);
            });
        },
        //第一种方案：用户输入01、02等，则展示成1、2，等于非小数时过滤第一位0
        limitNum(amount) {
            const regex = /^\d+(\.\d{0,2})?$/;
            // 如果输入不符合正则表达式
            if (!regex.test(amount)) {
                const match = amount.match(/\d+(\.\d{0,2})?/);
                if (match) {
                    amount = match[0];
                } else {
                    amount = '';
                }
            }
            return amount;
        },
        submitTotalAmount(submitflag, str, amount, item, index, str1) {
            if (submitflag) {
                if (str == 'close') {
                    this.maintenanceRuleForm.confirmAmount = this.tmpConfirmAmount == 'null' ? '0' : this.tmpConfirmAmount;
                    localStorage.removeItem('editTotalAmount');
                    this.editTotalAmount = false;

                    // 商品价值赋值
                    let tmpGoodsInfoRow = this.caseDetail.goodsInfo[index];
                    let tmpTotalAmount = this.tmpTotalObj[tmpGoodsInfoRow.id];
                    tmpGoodsInfoRow.goodsTotalAmount = tmpTotalAmount && tmpTotalAmount.goodsTotalAmount ? tmpTotalAmount.goodsTotalAmount : '';
                    this.maintenanceRuleForm.goodsTotalAmount = tmpGoodsInfoRow.goodsTotalAmount;

                    let tmpItem = JSON.parse(JSON.stringify(item));
                    tmpItem[str1] = tmpGoodsInfoRow.goodsTotalAmount;
                    this.$set(this.caseDetail.goodsInfo, index, tmpItem);
                } else {
                    this.maintenanceRuleForm.processMethod = this.goodsModeForm.processMethod;
                    this.maintenanceRuleForm.goodsTotalAmount = amount;
                    this.maintenanceRuleForm.goodsDtoList = this.caseDetail.goodsDtoList;
                    this.maintenanceRuleForm.confirmAmount = null;
                    this.submitAddCaseEnter(this.maintenanceRuleForm, 'edit_btn', 'amount');
                    this.$refs.elInput[0].blur();
                }
            } else {
                this.tmpConfirmAmount = String(this.maintenanceRuleForm.confirmAmount);
                localStorage.setItem('editTotalAmount', 'true');
                this.editTotalAmount = true;
            }
        },
        submitConfirmAmount(submitflag, str) {
            if (submitflag) {
                if (str == 'close') {
                    this.maintenanceRuleForm.confirmAmount = this.tmpConfirmAmount == 'null' ? '0' : this.tmpConfirmAmount;
                    localStorage.removeItem('editConfirmAmount');
                    this.editConfirmAmount = false;
                } else {
                    this.maintenanceRuleForm.processMethod = this.goodsModeForm.processMethod;
                    this.submitAddCaseEnter(this.maintenanceRuleForm, 'edit_btn');
                }
            } else {
                this.tmpConfirmAmount = String(this.maintenanceRuleForm.confirmAmount);
                localStorage.setItem('editConfirmAmount', 'true');
                this.editConfirmAmount = true;
            }
        },
        checkedVal(e, row, index, str) {
            let tmpRow = row;
            tmpRow[str] = Number(e.target.value);

            if (index == '-1') {
                if (str == 'deductibleRatio') {
                    if (tmpRow[str] > 100) {
                        tmpRow[str] = 100;
                    }
                    if (tmpRow[str] < 0) {
                        tmpRow[str] = 0;
                    }
                }
                this.maintenanceRuleForm = tmpRow;
                this.$forceUpdate();
            } else if (str != 'lossAmount' && str != 'shippingFee') {
                tmpRow = JSON.parse(JSON.stringify(row));

                if (str != 'price' && str != 'goodsTotalAmount' && str != 'claimNum') {
                    if (tmpRow[str] > 100) {
                        tmpRow[str] = 100;
                    }
                    if (tmpRow[str] < 0) {
                        tmpRow[str] = 0;
                    }
                }

                if (str == 'price' || str == 'goodsTotalAmount' || str == 'claimNum') {
                    this.$set(this.caseDetail.goodsInfo, index, tmpRow);

                    if (str == 'claimNum') {
                        tmpRow[str] = Number(tmpRow[str]) < 1 ? '1' : tmpRow[str];
                        this.priceChange(tmpRow, index, 'claimNum');
                    } else if (str == 'price') {
                        this.priceChange(tmpRow, index, 'price');
                    }
                }
            }
            // 更新table绑定key，更新table数据使用
            if (str != 'price' && str != 'crippledVal' && str != 'claimNum') {
                let dtoListRow = JSON.parse(JSON.stringify(this.caseDetail.goodsDtoList[0]));
                // tmpRow.injureType = dtoListRow[0].injureType;
                // tmpRow.exemptRate = dtoListRow[0].exemptRate;
                this.$set(this.caseDetail.goodsDtoList, index, tmpRow);
                let tmpList = JSON.parse(JSON.stringify(this.caseDetail.goodsDtoList));
                this.caseDetail.goodsDtoList = tmpList;
                // 损伤类型/免赔率不清空
                this.caseDetail.goodsDtoList[0].injureType = dtoListRow.injureType;
                this.caseDetail.goodsDtoList[0].exemptRate = dtoListRow.exemptRate;
                this.rendertable = !this.rendertable;
            }
            // 损失率和损失金额互斥
            if (str == 'lossRate' || str == 'lossAmount') {
                this.changeValue(tmpRow, index);
            }

            // 免赔率不为0时，关闭0免赔按钮
            if (str == 'exemptRate') {
                this.switchFlag = tmpRow[str] == 0 ? true : false;
                if (tmpRow[str] == 0) {
                    this.lossCalculation();
                }
            }
        },
        checkValue(row, index, str) {
            let tmpRow = JSON.parse(JSON.stringify(row));
            tmpRow[str] = str == 'exemptRate' && tmpRow[str] > 100 ? 100 : tmpRow[str];
            this.$set(this.caseDetail.goodsDtoList, index, tmpRow);
            let tmpList = JSON.parse(JSON.stringify(this.caseDetail.goodsDtoList));
            this.caseDetail.goodsDtoList = tmpList;
            this.$forceUpdate();
        },
        //获取所有的材料类型
        getFileTypeList() {
            this.instance.get('/tpa/api/enum/getCaseMaterialsTypeEnum', {}).then((res) => {
                if (res.data.code == 200) {
                    let data = res.data.data;
                    let arr = [];
                    data.forEach((value) => {
                        value.remark = '';
                        value.enumList.forEach((val) => {
                            value.remark += val.desc + '、';
                        });
                        value.remark && (value.remark = '所有' + value.remark.slice(0, -1));
                        arr = arr.concat(value.enumList);
                    });
                    this.caseMaterialsType = data;
                    this.fileTypeList = arr;
                }
            });
        },
        setPredictVal(echoFlag) {
            this.instance.get('/tpa/api/case/adjust/review/' + this.caseDetail.caseNo).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    if (data.data) {
                        let tmpAdjustData = data.data;
                        // 损失金额点击填入回显
                        this.tmpLossAmount = tmpAdjustData.goodsDtoList[0] ? tmpAdjustData.goodsDtoList[0].lossAmount : null;
                        // 运费点击填入回显
                        this.tmpShippingFee = tmpAdjustData.goodsDtoList[0] ? tmpAdjustData.goodsDtoList[0].shippingFee : null;
                        // 商品价值点击填入回显
                        this.tmpGoodsTotalAmount = tmpAdjustData.goodsTotalAmount || null;

                        if (echoFlag) {
                            // 预处理
                            data.data.accessoriesAmount = data.data.accessoriesAmount ? data.data.accessoriesAmount : 0;
                            data.data.repairAmount = data.data.repairAmount ? data.data.repairAmount : 0;
                            data.data.trialAmount = data.data.trialAmount ? data.data.trialAmount : 0;
                            data.data.confirmAmount = data.data.confirmAmount ? data.data.confirmAmount : 0;
                            data.data.shippingFee = data.data.shippingFee ? data.data.shippingFee : 0;

                            let tmpAdjustData = JSON.parse(JSON.stringify(data.data));
                            // review接口不回显(损失金额/商品价值/定损金额/投保比例/理算金额)
                            this.echoPageData(tmpAdjustData, true);
                        }
                    } else if (echoFlag) {
                        this.queryGoodsAveragePrice();
                        if (!this.caseDetail.goodsDtoList && this.caseDetail.goodsInfo && this.caseDetail.goodsInfo.length > 0) {
                            this.caseDetail.goodsDtoList = this.caseDetail.goodsInfo;
                            this.caseDetail.goodsDtoList.forEach((item, index) => {
                                let tmpItem = item;
                                tmpItem.goodsId = item.id;
                                this.$set(this.caseDetail.goodsDtoList, index, tmpItem);
                                this.injureTypeChange(item, index);
                                this.changeValue(item, index);
                            });
                        }
                    }
                }
            });
        },
        setModelVal(echoFlag) {
            this.instance.post('/tpa/api/case/bigModelRecord/findRecord/' + this.caseDetail.caseNo, {}).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    if (data.data) {
                        this.modelData = data.data;

                        if (echoFlag) {
                            // 预处理
                            data.data.accessoriesAmount = data.data.accessoriesAmount ? data.data.accessoriesAmount : 0;
                            data.data.repairAmount = data.data.repairAmount ? data.data.repairAmount : 0;
                            data.data.trialAmount = data.data.trialAmount ? data.data.trialAmount : 0;
                            data.data.confirmAmount = data.data.confirmAmount ? data.data.confirmAmount : 0;
                            data.data.shippingFee = data.data.shippingFee ? data.data.shippingFee : 0;

                            let tmpAdjustData = JSON.parse(JSON.stringify(data.data));
                            // review接口不回显(损失金额/商品价值/定损金额/投保比例/理算金额)
                            // this.echoPageData(tmpAdjustData, true);
                        }
                    } else if (echoFlag) {
                        this.queryGoodsAveragePrice();
                        if (!this.caseDetail.goodsDtoList && this.caseDetail.goodsInfo && this.caseDetail.goodsInfo.length > 0) {
                            this.caseDetail.goodsDtoList = this.caseDetail.goodsInfo;
                            this.caseDetail.goodsDtoList.forEach((item, index) => {
                                let tmpItem = item;
                                tmpItem.goodsId = item.id;
                                this.$set(this.caseDetail.goodsDtoList, index, tmpItem);
                                this.injureTypeChange(item, index);
                                this.changeValue(item, index);
                            });
                        }
                    }
                }
            });
        },
        //初始化图片地址
        initImgUrl(item) {
            if (item.imageList) {
                item.imageList.forEach((val) => {
                    val.url = val.materialPath ? this.OSSClient.signatureUrl(val.materialPath) : val.materialOriginalPath;

                    let imgType = ['.bmp', '.dib', '.pcp', '.dif', '.wmf', '.gif', '.jpg', '.tif', '.eps', '.psd', '.cdr', '.iff', '.tga', '.pcd', '.mpt', '.png', '.jpeg', '.psd', '.svg', '.ufo', '.dng', '.tga', '.eps', '.pcx', '.cdr'];
                    // 判断图片类型
                    let tmpUrlArr = val.url.split('?');
                    tmpUrlArr = tmpUrlArr[0];
                    let tmpLastIndex = tmpUrlArr.lastIndexOf('.');
                    let suffix = tmpLastIndex != -1 ? tmpUrlArr.slice(tmpLastIndex, tmpUrlArr.length).toLowerCase() : -1;
                    if (suffix.length > 6 && suffix.indexOf('%') != -1) {
                        let tmpSuffix = suffix.split('%')[0];
                        suffix = tmpSuffix ? (tmpSuffix == '.com' ? true : false) : '未识别';
                    }

                    // 图片后缀
                    val.isImg = Boolean(suffix != -1);
                    val.suffix = suffix;
                });
            }
        },
        // 其他费用 费用类型选择
        otherSelectChange(val) {
            var tmpIndex = this.otherSelectOption.findIndex((res) => {
                return val == res.code;
            });
            tmpIndex != -1 &&
                this.goodsModeForm.otherExpenses.forEach((item) => {
                    if (item.code == val) {
                        item.expenseName = this.otherSelectOption[tmpIndex].desc;
                    }
                });
        },
        // 定损计算
        lossCalculation() {
            let data = {
                caseNo: this.caseDetail.caseNo,
                goodsList: this.caseDetail.goodsDtoList,
                repairAmount: this.maintenanceRuleForm.repairAmount,
                shippingFee: this.maintenanceRuleForm.shippingFee,
                accessoriesAmount: this.maintenanceRuleForm.accessoriesAmount,
                deductibleRatio: this.maintenanceRuleForm.deductibleRatio,
                trialAmount: this.maintenanceRuleForm.trialAmount,
                goodsTotalAmount: this.caseDetail.goodsInfo[0].goodsTotalAmount,
                processMethod: this.goodsModeForm.processMethod
            };

            this.calculateData(data, true);
        },
        // 保存其他费用
        saveOtherExpense() {
            let json = {
                caseNo: this.caseDetail.caseNo,
                expenseVos: this.goodsModeForm.otherExpenses
            };
            this.instance.post('/tpa/api/case/enter/adjust/saveOtherExpense', json).then((res) => {});
            this.initOtherImg();
        },
        // 定损计算
        calculateData(transferData, saveFlag) {
            if (localStorage.getItem('editTotalAmount')) {
                this.$message.error('点击了修改 “商品价值” ，但还未提交');
                return;
            }
            if (localStorage.getItem('editConfirmAmount')) {
                this.$message.error('点击了修改 “赔偿确认金额” ，但还未提交');
                return;
            }
            let goodsList = transferData.goodsList;
            // let showError = false;
            // if (transferData.processMethod === 10) {
            //     goodsList.forEach((goods, index) => {
            //         if (goods.exemptRate < 10) {
            //             showError = true;
            //         }
            //     });
            // } else if (transferData.deductibleRatio < 10) {
            //     showError = true;
            // }

            // if (showError) {
            //     this.$message.error('按协议约定,免赔率必须大于10%');
            //     return;
            // }

            transferData.otherExpenses = this.goodsModeForm.otherExpenses;

            this.instance.post('/tpa/api/case/enter/adjust/calculate', transferData).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    setTimeout(() => {
                        this.updateCalculateAmount(this.caseDetail.caseNo);
                    }, 1000);
                    // 定损计算成功后，获取提示风险
                    this.getShowRiskInfo();
                    // 定损计算成功后，获取理算风险
                    this.getAdjustRiskInfo();
                    let tmpData = data.data;
                    tmpData.accessoriesAmount = tmpData.accessoriesAmount ? tmpData.accessoriesAmount : 0;
                    tmpData.repairAmount = tmpData.repairAmount ? tmpData.repairAmount : 0;
                    if (tmpData.processMethod == 10) {
                        // 理算总金额展示
                        this.goodsTotalAmountCount(tmpData);
                        // 初始化损失信息
                        tmpData.goodsDtoList &&
                            tmpData.goodsDtoList.forEach((item, index) => {
                                this.injureTypeChange(item, index);
                                this.changeValue(item, index);
                            });
                        this.caseDetail.goodsDtoList = tmpData.goodsDtoList;
                    } else {
                        this.maintenanceRuleForm = tmpData;
                    }
                    this.submitAddCaseEnter(tmpData);
                } else {
                    this.$message.error(res.data.message ? res.data.message : '接口出错，请联系管理员');
                }
            });
        },
        // 更新理算总金额
        updateCalculateAmount(caseNo) {
            this.instance.get('/tpa/api/case/detail/' + caseNo, {}).then((res) => {
                this.caseLoading = false;
                if (res.data.code === 200 && res.data.data) {
                    this.adjustedTotalAmount = res.data.data.calculateAmount;
                } else {
                    this.adjustedTotalAmount = null;
                    console.log('更新理算总金额字段失败；');
                }
            });
        },
        //获取受损程度
        getDamagedDegreeEnum() {
            this.instance.get('/tpa/api/enum/getDamagedDegreeEnum', { params: { caseNo: this.caseDetail.caseNo } }).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.damagedDegreeOption = data.data;
                }
            });
        },
        // 梳理商品信息对应商品价值
        combGoodsAmount(data) {
            data.goodsInfo &&
                data.goodsInfo.forEach((item, index) => {
                    this.tmpTotalObj[item.id] = JSON.parse(JSON.stringify(item));
                    item.goodsTotalAmount = item.goodsTotalAmount ? item.goodsTotalAmount : null;
                    this.$set(data.goodsInfo, index, JSON.parse(JSON.stringify(item)));
                    this.$forceUpdate();
                });
        },
        // 获取其他费用选项
        getOtherSelect() {
            this.instance.get('/tpa/api/enum/getOtherExpense', {}).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.otherSelectOption = data.data;
                }
            });
        },
        //获取案件理算信息
        getAdjust() {
            this.instance.get('/tpa/api/case/enter/adjust/getAdjust?caseNo=' + this.caseDetail.caseNo).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    if (data.data && data.data.goodsDtoList) {
                        // 预处理
                        data.data.accessoriesAmount = data.data.accessoriesAmount ? data.data.accessoriesAmount : 0;
                        data.data.repairAmount = data.data.repairAmount ? data.data.repairAmount : 0;
                        data.data.trialAmount = data.data.trialAmount ? data.data.trialAmount : 0;
                        data.data.confirmAmount = data.data.confirmAmount ? data.data.confirmAmount : 0;
                        data.data.shippingFee = data.data.shippingFee ? data.data.shippingFee : 0;

                        let tmpAdjustData = JSON.parse(JSON.stringify(data.data));
                        // 定损处理卡片-页面数据回显
                        this.echoPageData(tmpAdjustData);
                        return;

                        // 赋值
                        this.maintenanceRuleForm = data.data;

                        this.caseDetail.goodsDtoList = data.data.goodsDtoList;
                        this.goodsModeForm.processMethod = data.data.processMethod;
                        this.goodsModeForm.otherExpenses = data.data.otherExpenses;

                        if (this.OSSClient) {
                            this.initOtherImg();
                        } else {
                            setTimeout(() => {
                                this.initOtherImg();
                            }, 2000);
                        }

                        // 赔偿赋值
                        if (tmpAdjustData.goodsDtoList) {
                            let tmpList;
                            this.caseDetail.goodsDtoList = tmpAdjustData.goodsDtoList;
                            this.caseDetail.goodsDtoList.forEach((item, index) => {
                                // 商品信息-索赔金额-定损计算过使用定损计算数量
                                this.caseDetail.goodsInfo[index].claimNum = item.claimNum;
                                this.$forceUpdate();
                                // 定损过案件，设置损失单价，计算小计金额
                                this.priceChange(item, index);
                                // 回显赔偿列表互斥
                                this.changeValue(item, index);
                                // 损伤类型互斥
                                this.injureTypeChange(item, index);
                            });
                        }
                        // 商品价值赋值
                        if (this.caseDetail.goodsInfo && this.caseDetail.goodsInfo[0]) {
                            this.caseDetail.goodsInfo[0].goodsTotalAmount = tmpAdjustData.goodsTotalAmount;
                            this.combGoodsAmount(this.caseDetail);
                        }

                        // 理算总金额展示
                        this.goodsTotalAmountCount(data.data);
                    } else {
                        // 2 现在使用这个逻辑
                        // 没有数据时,优先从review接口获取数据回显
                        // 400以内的金额展示蓝色回显文字
                        if (this.caseDetail.claimAmount) {
                            this.setPredictVal(true);
                        } else {
                            // 1 ai助手上线前,使用下面逻辑,ai助手8.5开始staging环境
                            // 1.1 review无值,还是用下面原来逻辑
                            // 案件初次进来没有定损计算结果，data=null，需要将商品信息数据复制过来
                            this.queryGoodsAveragePrice();
                            if (!this.caseDetail.goodsDtoList && this.caseDetail.goodsInfo && this.caseDetail.goodsInfo.length > 0) {
                                this.caseDetail.goodsDtoList = this.caseDetail.goodsInfo;
                                this.caseDetail.goodsDtoList.forEach((item, index) => {
                                    let tmpItem = item;
                                    tmpItem.goodsId = item.id;
                                    this.$set(this.caseDetail.goodsDtoList, index, tmpItem);
                                    this.injureTypeChange(item, index);
                                    this.changeValue(item, index);
                                });
                            }
                        }
                    }
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        echoPageData(echoAdjustData, echoAllFlag) {
            let tmpAdjustData = JSON.parse(JSON.stringify(echoAdjustData));

            // 商品价值赋值
            if (this.caseDetail.goodsInfo && this.caseDetail.goodsInfo[0] && !echoAllFlag) {
                this.caseDetail.goodsInfo[0].goodsTotalAmount = tmpAdjustData.goodsTotalAmount;
                this.combGoodsAmount(this.caseDetail);
            }
            // 赋值
            this.maintenanceRuleForm = tmpAdjustData;

            if (!echoAllFlag) {
                this.caseDetail.goodsDtoList = tmpAdjustData.goodsDtoList;
            } else {
                tmpAdjustData.goodsDtoList[0].adjustLossAmount = '';
                tmpAdjustData.goodsDtoList[0].lossAmount = '';
                tmpAdjustData.goodsDtoList[0].shippingFee = '';
                tmpAdjustData.goodsDtoList[0].insureRate = '';
                tmpAdjustData.goodsDtoList[0].adjustAmount = '';
                this.caseDetail.goodsDtoList = tmpAdjustData.goodsDtoList;
            }
            this.goodsModeForm.processMethod = tmpAdjustData.processMethod;
            this.goodsModeForm.otherExpenses = tmpAdjustData.otherExpenses;

            if (this.OSSClient) {
                this.initOtherImg();
            } else {
                setTimeout(() => {
                    this.initOtherImg();
                }, 2000);
            }
            // 赔偿赋值
            if (tmpAdjustData.goodsDtoList) {
                let tmpList;

                // this.caseDetail.goodsDtoList = tmpAdjustData.goodsDtoList;
                this.caseDetail.goodsDtoList.forEach((item, index) => {
                    // 商品信息-索赔金额-定损计算过使用定损计算数量
                    this.caseDetail.goodsInfo[index].claimNum = item.claimNum;
                    this.$forceUpdate();
                    // 定损过案件，设置损失单价，计算小计金额
                    this.priceChange(item, index);
                    // 回显赔偿列表互斥
                    this.changeValue(item, index);
                    // 损伤类型互斥
                    this.injureTypeChange(item, index);
                });
            }

            // 理算总金额展示
            if (!echoAllFlag) {
                this.goodsTotalAmountCount(echoAdjustData);
            }
        },
        // 初始化其他费用图片
        initOtherImg() {
            if (this.goodsModeForm.otherExpenses && this.goodsModeForm.otherExpenses.length > 0) {
                let tmpOtherExpenses = JSON.parse(JSON.stringify(this.goodsModeForm.otherExpenses));
                for (let i = 0; i < tmpOtherExpenses.length; i++) {
                    let tmpExpenseImages = tmpOtherExpenses[i].expenseImages;
                    if (tmpExpenseImages) {
                        for (let index = 0; index < tmpExpenseImages.length; index++) {
                            if (tmpExpenseImages[index].indexOf('http') == -1) {
                                tmpExpenseImages[index] = this.OSSClient.signatureUrl(tmpExpenseImages[index]);
                            }
                        }
                    }
                }
                this.goodsModeForm.otherExpenses = tmpOtherExpenses;
                this.$forceUpdate();
            }
        },
        //保存案件录入信息
        submitAddCaseEnter(jsonData, editStr, editVal) {
            jsonData.caseNo = this.caseDetail.caseNo;
            jsonData.otherExpenses = this.goodsModeForm.otherExpenses;
            this.instance.post('/tpa/api/case/enter/adjust/addCaseEnter', jsonData).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    localStorage.removeItem('editTotalAmount');
                    localStorage.removeItem('editConfirmAmount');
                    if (editStr == 'edit_btn') {
                        this.$message.success('提交成功');
                    } else {
                        this.$message.success('定损计算成功');
                    }

                    if (editVal == 'amount') {
                        this.caseDetail.goodsInfo[0].goodsTotalAmount = this.maintenanceRuleForm.goodsTotalAmount;
                        this.combGoodsAmount(this.caseDetail);
                    }

                    if (editStr == 'edit_btn') {
                        setTimeout(() => {
                            if (editVal == 'amount') {
                                this.editTotalAmount = false;
                            } else {
                                this.editConfirmAmount = false;
                            }
                        }, 500);
                    }
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        // 其他费用 添加/删除按钮
        editOtherList(index, btnStr) {
            if (!this.goodsModeForm.otherExpenses) {
                this.goodsModeForm.otherExpenses = [];
            }
            if (btnStr == 'del') {
                this.goodsModeForm.otherExpenses.splice(index, 1);
                this.saveOtherExpense();
                this.$forceUpdate();
            } else if (this.goodsModeForm.otherExpenses.length < 16) {
                this.goodsModeForm.otherExpenses.push({
                    code: '',
                    expenseAmount: '',
                    expenseName: '',
                    expenseImages: []
                });
            } else {
                this.$message.error('其他费用，暂时只能添加15项');
            }
        },
        submitMaintenanceForm() {
            this.$refs.maintenanceRuleForm.validate((valid) => {
                valid && this.lossCalculation();
            });
        },
        // 轮询查找商品参考价
        queryGoodsAveragePrice() {
            this.caseDetail.goodsInfo &&
                this.caseDetail.goodsInfo.forEach((item, index) => {
                    item.lossRateDisabled = false;
                    item.lossAmountDisabled = false;
                    this.getGoodsPriceRelations(item, index);
                });
        },
        // 修改参考价格 实时计算 小计金额
        priceChange(row, index, str) {
            // 先进行数字校验
            if (str) {
                let tmpNum = this.limitNum(row[str]);
                let tmpRow = JSON.parse(JSON.stringify(row));
                tmpRow[str] = tmpNum;
                this.$set(this.caseDetail.goodsInfo, index, tmpRow);
            }

            if (row.claimNum >= 0 && row.price >= 0) {
                let tmpPrice1 = (row.claimNum * row.price).toFixed(2);
                row.subtotalPrice = tmpPrice1;

                let tmpGoodsInfoRow = this.caseDetail.goodsInfo[index];
                if (tmpGoodsInfoRow) {
                    tmpGoodsInfoRow.price = row.price;
                    tmpGoodsInfoRow.subtotalPrice = tmpPrice1;
                    let tmpTotalAmount = this.tmpTotalObj[tmpGoodsInfoRow.id];
                    tmpGoodsInfoRow.goodsTotalAmount = tmpTotalAmount && tmpTotalAmount.goodsTotalAmount ? tmpTotalAmount.goodsTotalAmount : tmpPrice1;
                    this.maintenanceRuleForm.goodsTotalAmount = tmpGoodsInfoRow.goodsTotalAmount;
                    this.$set(this.caseDetail.goodsInfo, index, tmpGoodsInfoRow);
                }

                let tmpGoodsDtoList = this.caseDetail.goodsDtoList;
                if (tmpGoodsDtoList && tmpGoodsDtoList[index]) {
                    tmpGoodsDtoList[index][str] = row[str];
                    this.$forceUpdate();
                }
            }
        },
        // 计算小计金额
        countPrice() {
            if (this.caseDetail.goodsInfo && this.caseDetail.goodsInfo.length > 0) {
                this.caseDetail.goodsInfo.forEach((item, index) => {
                    this.priceChange(item, index);
                });
            }
        },
        onlyNumOnePoint(number_only, str) {
            number_only = String(number_only);
            //先把非数字的都替换掉，除了数字和小数点
            number_only = number_only.replace(/[^\d.]/g, '');
            //必须保证第一个为数字而不是小数点
            number_only = number_only.replace(/^\./g, '');
            //保证只有出现一个小数点而没有多个小数点
            number_only = number_only.replace(/\.{2,}/g, '.');
            //保证小数点只出现一次，而不能出现两次以上
            number_only = number_only.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
            //保证只能输入两个小数
            number_only = number_only.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
            if (str == 'exemptRate' || str == 'deductibleRatio') {
                return Number(number_only) == 0 ? 0 : Number(number_only);
            } else {
                return Number(number_only) == 0 ? '' : Number(number_only);
            }
        },
        // 损伤类型
        injureTypeChange(row, index) {
            let tmpRow = row;
            if (tmpRow.injureType == 10) {
                tmpRow.crippledValDisabled = true;
                tmpRow.crippledVal = '';
            } else {
                tmpRow.crippledValDisabled = false;
            }
            this.$set(this.caseDetail.goodsDtoList, index, tmpRow);
            let tmpList = JSON.parse(JSON.stringify(this.caseDetail.goodsDtoList));
            this.caseDetail.goodsDtoList = tmpList;
            this.$forceUpdate();
        },
        changeValue(row, index) {
            let tmpRow = row;
            // lossAmount 损失金额
            tmpRow.lossAmountDisabled = false;
            // lossRate 损失率
            tmpRow.lossRateDisabled = false;

            if (row.lossRate > 100) {
                row.lossRate = 100;
            }

            tmpRow.lossRate = tmpRow.lossRate ? tmpRow.lossRate : '';
            tmpRow.lossAmount = tmpRow.lossAmount ? tmpRow.lossAmount : '';
            if (!isNaN(tmpRow.lossRate) && tmpRow.lossRate.toString().indexOf(' ') == -1) {
                if (String(tmpRow.lossRate).length > 0) {
                    tmpRow.lossAmount = '';
                    tmpRow.lossAmountDisabled = true;
                } else {
                    tmpRow.lossAmountDisabled = false;
                }
            }

            if (!isNaN(tmpRow.lossAmount) && tmpRow.lossAmount.toString().indexOf(' ') == -1) {
                if (String(tmpRow.lossAmount).length > 0) {
                    tmpRow.lossRate = '';
                    tmpRow.lossRateDisabled = true;
                } else {
                    tmpRow.lossRateDisabled = false;
                }
            }

            this.$set(this.caseDetail.goodsDtoList, index, row);
            let tmpList = JSON.parse(JSON.stringify(this.caseDetail.goodsDtoList));
            this.caseDetail.goodsDtoList = tmpList;
            this.$forceUpdate();
        },
        //通过商品名称获取商品比价
        getGoodsPriceRelations(item, index) {
            let tmpItem = item;
            tmpItem.price = 0;
            // tmpItem.subtotalPrice = 100
            this.$set(this.caseDetail.goodsInfo, index, tmpItem);
            this.countPrice();
        },
        // 商品参考价格
        productReferencePrice(showVisible, item, index) {
            this.goodsVisible = showVisible;
            if (this.goodsVisible) {
                let tmpGoodsInfoRow = this.caseDetail.goodsInfo[index];
                tmpGoodsInfoRow.price = item.price;
                this.$set(this.caseDetail.goodsInfo, index, tmpGoodsInfoRow);
                if (showVisible) {
                    this.tmpProductItem = {
                        row: item,
                        rowIndex: index
                    };
                    this.queryGoodsForm.condition = item.goodsName;
                    showVisible && this.radioInput(this.tabPosition);
                }
                this.$forceUpdate();
            }
        },
        //获取商品品类第一级
        getFirstCategory() {
            this.instance.get('/tpa/api/goods/category/getFirstCategory', {}).then((res) => {
                if (res.data.code == 200) {
                    this.firstCategoryOption = res.data.data;
                }
            });
        },
        // 点击使用该商品信息按钮
        useGoodsInfo() {
            let multipleSelection = this.multipleSelection;

            if (multipleSelection.length > 1) {
                this.$message.error('只支持使用一件商品信息！');
                return;
            }

            if (multipleSelection.length == 1) {
                this.tmpProductItem.row.price = this.multipleSelection[0].price;
                let tmpRow = this.tmpProductItem.row;
                let tmpIndex = this.tmpProductItem.rowIndex;
                this.$set(this.caseDetail.goodsInfo, tmpIndex, tmpRow);
                let tmpList = JSON.parse(JSON.stringify(this.caseDetail.goodsInfo));
                this.caseDetail.goodsDtoList = tmpList;
                // 商品数量联动
                this.goodsNumLinkage(this.tmpProductItem.row.price);
                // 损失单价改变计算
                this.priceChange(tmpRow, tmpIndex);
                // 点击返回按钮
                this.productReferencePrice(false, tmpRow, tmpIndex);
            } else {
                this.$message.error('抱歉，请先选择商品！');
            }
        },
        // 商品数量联动
        goodsNumLinkage(goodsPrice) {
            // 计算商品价值
            let goodsInfo = this.caseDetail.goodsInfo[0];
            let totalPrice = Math.round(this.goodsNum * goodsPrice * 100) / 100;
            goodsInfo.goodsTotalAmount = totalPrice;

            // 商品价值编辑
            this.editTotalAmount = true;
            this.checkedVal({ target: { value: totalPrice } }, goodsInfo, 0, 'goodsTotalAmount');
            // 提交
            setTimeout(() => {
                this.submitTotalAmount(true, '', goodsInfo.goodsTotalAmount);
            }, 200);
        },
        //点击添加商品的保存按钮
        handleAddGoods(saveFlag) {
            if (saveFlag) {
                this.$refs.commodityRef.validate((valid) => {
                    if (valid) {
                        let json = JSON.parse(JSON.stringify(this.addGoodsForm));
                        json.goodsCategoryId = json.goodsCategoryId[1];
                        this.instance.post('/tpa/api/goods/addGoods', json).then((res) => {
                            if (res.data.code == 200) {
                                this.$message.success('操作成功！');
                                this.dialogVisibleAddGoods = false;
                                this.$refs.commodityRef.resetFields();
                                this.getGoodsList();
                                this.addGoodsForm = {
                                    mall: '',
                                    price: '',
                                    shopName: '',
                                    goodsCategoryId: '',
                                    goodsName: ''
                                };
                            } else {
                                this.$message.error(res.data.message);
                            }
                        });
                    }
                });
            } else {
                this.dialogVisibleAddGoods = false;
                this.$refs.commodityRef.resetFields();
            }
        },
        //通过商品名称获取商品列表
        getGoodsList() {
            let apiUrl = this.tabPosition == 1 ? '/tpa/api/goods/getGoodsList' : '/tpa/api/goods/getSecondHandGoodsList';

            this['priceRableLoading' + this.tabPosition] = true;
            this.instance.post(apiUrl, this.queryGoodsForm).then((res) => {
                this['priceRableLoading' + this.tabPosition] = false;
                let data = res.data;
                if (data.code == 200) {
                    this['allPriceTableData' + this.tabPosition] = data.data.list;
                    this.$forceUpdate();
                }
            });
        },
        //点击添加商品类目的保存按钮
        handleAddGoodsCategory(saveFlag) {
            if (saveFlag) {
                this.$refs.categoryRuleForm.validate((valid) => {
                    if (valid) {
                        this.instance.post('/tpa/api/goods/category/addGoodsCategory', this.addGoodsCategoryForm).then((res) => {
                            if (res.data.code == 200) {
                                this.$message.success('操作成功！');
                                this.dialogVisibleAddGoodsCategory = false;
                                this.$refs.categoryRuleForm.resetFields();
                                this.addGoodsCategoryForm.name = '';
                                this.addGoodsCategoryForm.parentId = '';
                                this.getGoodsList();
                                this.getCategoryTree();
                            } else {
                                this.$message.error(res.data.message);
                            }
                        });
                    }
                });
            } else {
                this.dialogVisibleAddGoodsCategory = false;
                this.$refs.categoryRuleForm.resetFields();
            }
        },
        // 理算总金额展示
        goodsTotalAmountCount(tmpData) {
            let goodsTotalAmount = tmpData.trialAmount;
            let detailAmount = '';
            if (tmpData.goodsDtoList && tmpData.goodsDtoList.length > 0) {
                let tmpGoodsDtoList = tmpData.goodsDtoList;

                if (tmpData.goodsDtoList.length > 1) {
                    tmpGoodsDtoList &&
                        tmpGoodsDtoList.forEach((item, index) => {
                            detailAmount += index != tmpGoodsDtoList.length - 1 ? item.adjustAmount + '+' : item.adjustAmount;
                        });
                    this.caseDetail.tmpTotalAmount = detailAmount && String(detailAmount).length > 0 ? detailAmount + ' = ' + goodsTotalAmount : '';
                } else if (tmpData.goodsDtoList.length > 0) {
                    detailAmount = tmpGoodsDtoList[0].adjustAmount;
                    this.caseDetail.tmpTotalAmount = detailAmount && String(detailAmount).length > 0 ? detailAmount : '';
                }
            } else {
                this.caseDetail.tmpTotalAmount = goodsTotalAmount;
            }
            this.$forceUpdate();
        },
        // 获取理算风险
        getAdjustRiskInfo() {
            this.instance.get('/tpa/api/case/enter/adjust/getRiskInfo?caseNo=' + this.caseDetail.caseNo).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.highRiskInfos = data.data ? [data.data] : null;
                    this.activeNameArray = [];
                    if (this.highRiskInfos) {
                        this.riskNumData.num = 0;
                        this.highRiskInfos.forEach((item, index) => {
                            this.riskNumData.num = this.riskNumData.num + item.highCount + item.mediumCount + item.lowCount;
                            this.riskNumData.color = item.highCount > 0 ? 'errorColorBgc' : item.mediumCount > 0 ? 'warningColorBgc' : 'successColorBgc';
                            this.activeNameArray.push(index);
                        });

                        this.previewRiskData = this.highRiskInfos;
                    }
                }
            });
        },
        // 获取投保比例异常提示
        getShowRiskInfo() {
            this.instance.get('/tpa/api/case/risk/getRiskInfo?caseNo=' + this.caseDetail.caseNo).then((res) => {
                let data = res.data;
                this.riskErrorList = [];
                if (data.code == 200 && data.data && data.data.length > 0) {
                    data.data.forEach((item) => {
                        if (item.riskCode == 'C3') {
                            this.riskErrorList.push(item.riskDesc.replace(/\n|\r\n/g, '<br>').replace(/ /g, ' &nbsp'));
                        }
                    });

                    if (this.riskErrorList.length > 0) {
                        this.errorVisible = true;
                    }
                }
            });
        },
        handleCurrentChange(val) {
            if (this.proupType == 'medicine') {
                this.medicineForm.pageNo = val;
                this.getMedicineList();
            } else {
                this.queryGoodsForm.pageNo = val;
                this.getGoodsList();
            }
        },
        handleSizeChange(val) {
            if (this.proupType == 'medicine') {
                this.medicineForm.pageSize = val;
                this.getMedicineList();
            } else {
                this.queryGoodsForm.pageSize = val;
                this.getGoodsList();
            }
        },
        searChGoodsList() {
            if (!this.queryGoodsForm.condition) {
                this.$message.error('搜索框不能为空！');
                return;
            }
            this.queryGoodsForm.pageNo = 1;

            this.getGoodsList();
        },
        // 初始化OSS
        initOSSClient() {
            this.OSSClient = null;
            this.resetOssNum++;
            this.instance
                .get('/tpa/api/aliyun/oss/access')
                .then((res) => {
                    let data = res.data;
                    if (data.code == 200) {
                        this.OSSClient = new OSS({
                            accessKeyId: data.data.accessKeyId,
                            accessKeySecret: data.data.accessKeySecret,
                            stsToken: data.data.securityToken,
                            // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou。
                            region: data.data.region,
                            bucket: data.data.bucket
                        });
                    } else {
                        console.log('oss初始化失败，正在重试！');

                        if (this.resetOssNum < 20) {
                            setTimeout(() => {
                                this.initOSSClient();
                            }, 1000);
                        } else {
                            console.log('oss初始化超过20s，失败!');
                        }
                    }
                })
                .catch((err) => {
                    console.log('oss初始化失败-');
                    console.log(err);
                });
        },
        handleSelectClick(selection) {
            if (selection && selection.length > 1) {
                const del_row = selection.shift();
                if (this.$refs.multipleTable.length > 0) {
                    this.$refs.multipleTable[0].toggleRowSelection(del_row);
                } else if (this.$refs.multipleTable) {
                    this.$refs.multipleTable.toggleRowSelection(del_row);
                }
            }
            this.multipleSelection = selection;
        },
        // 查勘信息卡片按钮操作，设置定损卡片是否可编辑
        setisEditInfo(data) {
            this.isEditInfo = data ? false : this.isEditInfo;
        },
        // 处方/非处方药查询
        medicineQuery(medicineItem, medicineIndex) {
            this.goodsVisible = true;
            this.proupType = 'medicine';
            this.medicineForm.condition.name = medicineItem.goodsName;
            this.getMedicineList();
        },
        handleSelect(selectItem) {
            this.addMedicineForm.medicineName = selectItem.medicineName || '';
            this.addMedicineForm.medicineProperty = selectItem.medicineProperty || '';
            this.addMedicineForm.approvalNumber = selectItem.approvalNumber || '';
            this.addMedicineForm.manufacturer = selectItem.manufacturer || '';
            this.addMedicineForm.medicineType = selectItem.medicineType || '';
            this.addMedicineForm.specification = selectItem.specification || '';
            this.addMedicineForm.price = selectItem.price || '';
        },
        changeSelect() {
            this.addMedicineForm = { ...this.addMedicineForm };
        },
        closeDialog() {
            if (this.medicineProup) {
                this.goodsVisible = true;
                this.medicineProup = false;
            } else {
                this.goodsVisible = false;
            }
        },
        getMedicineList() {
            this.priceRableLoading = true;
            this.instance.post('/tpa/api/medicine/paging', this.medicineForm).then((res) => {
                this.priceRableLoading = false;
                let data = res.data;
                if (data.code == 200) {
                    this.medicineList = data.data.list;
                    this.medicineForm.total = data.data.total;
                }
                this.medicineForm.condition = {
                    isCalibration: 1,
                    name: this.medicineForm.condition.name
                };
            });
        },
        resetForm() {
            this.medicineProup = false;
            this.$refs.commodityRef.resetFields();
        },
        addDrusList() {
            this.$refs.commodityRef.validate((valid) => {
                if (valid) {
                    this.instance.post('/tpa/api/medicine/add', this.addMedicineForm).then((res) => {
                        let data = res.data;
                        if (data.code == 200) {
                            this.addMedicineForm = {
                                medicineName: '',
                                medicineProperty: '',
                                approvalNumber: '',
                                manufacturer: '',
                                medicineType: '',
                                specification: '',
                                price: ''
                            };
                            this.medicineProup = false;
                            this.$message.success('药品添加成功！');
                        } else {
                            this.$message.error(data.message);
                        }
                    });
                }
            });
        },
        querySearch(queryString, cb) {
            let queryList = {
                condition: {
                    isCalibration: 1,
                    name: queryString
                },
                pageNo: 1,
                pageSize: 999
            };
            let results = [];
            this.instance.post('/tpa/api/medicine/paging', queryList).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    results = data.data.list;
                    results.forEach((res) => {
                        res.value = res.medicineName;
                    });
                } else {
                    results = [];
                }
                cb(results);
            });
        },
        getMedicineProperty() {
            this.medicinePropertyOptions = [];
            this.instance.get('/tpa/api/enum/getMedicinePropertyEnum').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.medicinePropertyOptions = data.data;
                }
            });
        },
        getMedicineType() {
            this.medicineTypeOptions = [];
            this.instance.get('/tpa/api/enum/getMedicineTypeEnum').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.medicineTypeOptions = data.data;
                }
            });
        },

        processImgList(caseNo) {
            let materialsList = [];
            this.instance.post('/tpa/api/case/materials/' + caseNo, {}).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    materialsList = data.data;
                    this.instance.get('/tpa/api/enum/getCaseMaterialsTypeEnum', {}).then((res) => {
                        if (res.data.code === 200) {
                            let caseMaterialsType = res.data.data;
                            this.handlerImgList(materialsList, caseMaterialsType);
                        }
                    });
                }
            });
        },

        handlerImgList(materialsList, caseMaterialsType) {
            let images = [];
            if (materialsList && materialsList.length > 0) {
                materialsList.forEach((item) => {
                    let url = '';
                    if (item.materialPath) {
                        url = this.OSSClient.signatureUrl(item.materialPath);
                    } else {
                        url = item.materialOriginalPath;
                    }
                    let materialParentTypeName = caseMaterialsType.find((item1) => item1.parentCode === item.materialParentType).parentName;
                    let calibratedResult = {};
                    calibratedResult = this.calibratedOcrResult.find((item1) => item1.materialHash === item.signHash);
                    let originResult = {};
                    let deepCopyOriginOcrResult = JSON.parse(JSON.stringify(this.originOcrResult));
                    originResult = deepCopyOriginOcrResult.find((item1) => item1.materialHash === item.signHash);

                    // 判断图片类型
                    let tmpUrlArr = url.split('?');
                    tmpUrlArr = tmpUrlArr[0];
                    let tmpLastIndex = tmpUrlArr.lastIndexOf('.');
                    let suffix = tmpLastIndex != -1 ? tmpUrlArr.slice(tmpLastIndex, tmpUrlArr.length).toLowerCase() : -1;
                    if (suffix.length > 6 && suffix.indexOf('%') != -1) {
                        let tmpSuffix = suffix.split('%')[0];
                        suffix = tmpSuffix ? (tmpSuffix == '.com' ? true : false) : '未识别';
                    }

                    // 图片后缀
                    item.isImg = Boolean(suffix != -1);
                    item.suffix = suffix;

                    let materials = {
                        id: item.id,
                        suffix: item.suffix,
                        isImg: item.isImg,
                        url: url,
                        materialHash: item.signHash,
                        materialParentType: item.materialParentType,
                        materialParentTypeName: materialParentTypeName,
                        materialType: item.materialType,
                        materialTypeName: item.materialTypeName,
                        calibratedResult: calibratedResult,
                        originResult: originResult
                    };
                    this.imgUrlList.push(materials);
                    images.push(materials);
                });
                this.imgUrlList.sort((a, b) => {
                    return a.materialParentType - b.materialParentType;
                });
            }
            this.currentActiveId = this.imgUrlList[0].id;
            this.currentActiveMaterial = this.imgUrlList[0];
            this.thumbnailActiveMaterialParentTypeName = this.imgUrlList[0].materialParentTypeName;
            images.forEach((item) => {
                if (!this.imgGroup[item.materialParentTypeName]) {
                    this.imgGroup[item.materialParentTypeName] = [];
                }
                this.imgGroup[item.materialParentTypeName].push(item);
            });

            caseMaterialsType.forEach((item) => {
                if (this.imgGroup[item.parentName]) {
                    let tmpItem = {
                        materialParentType: item.parentCode,
                        materialParentTypeName: item.parentName,
                        imgList: this.imgGroup[item.parentName]
                    };
                    this.imgGroupList.push(tmpItem);
                }
            });
            this.imgGroupList.sort((a, b) => {
                return a.materialParentType - b.materialParentType;
            });
        },

        previewPrev() {
            this.ocrRecognitionConfirm();
            let current = this.alreadyOcrList.find((item) => item.id === this.currentActiveId);
            this.ocrResult = current ? current.calibratedResult : {};
            let index = this.imgUrlList.findIndex((item) => item.id === this.currentActiveId);
            if (index <= 0) {
                // 切换右侧分类
                if (index - 1 < 0) {
                    this.currentActiveId = this.imgUrlList[0].id;
                    this.currentActiveMaterial = this.imgUrlList[0];
                    this.subsetChange(this.imgUrlList[this.imgUrlList.length - 1]);
                } else {
                    let item = this.imgUrlList[index - 1];
                    this.currentActiveMaterial = item;
                    this.currentActiveId = item.id;
                    this.subsetChange(item);
                }
            } else {
                this.currentActiveId = this.imgUrlList[index - 1].id;
                this.currentActiveMaterial = this.imgUrlList[index - 1];
                if (this.currentActiveMaterial.calibratedResult) {
                    this.ocrResult = this.currentActiveMaterial.calibratedResult;
                } else {
                    this.ocrResult = {};
                }
                this.$refs.carouselEl.prev();
            }
        },
        previewNext() {
            this.ocrRecognitionConfirm();
            let index = this.imgUrlList.findIndex((item) => item.id === this.currentActiveId);
            if (index === this.imgUrlList.length - 1) {
                // 切换右侧分类
                if (index + 1 >= this.imgUrlList.length) {
                    this.currentActiveMaterial = this.imgUrlList[this.imgUrlList.length - 1];
                    this.currentActiveId = this.imgUrlList[this.imgUrlList.length - 1].id;
                    this.subsetChange(this.imgUrlList[0]);
                } else {
                    this.currentActiveId = this.imgUrlList[index + 1].id;
                    this.currentActiveMaterial = this.imgUrlList[index + 1];
                    this.subsetChange(this.imgUrlList[index + 1]);
                }
            } else {
                this.currentActiveId = this.imgUrlList[index + 1].id;
                this.currentActiveMaterial = this.imgUrlList[index + 1];
                if (this.currentActiveMaterial.calibratedResult) {
                    this.ocrResult = this.currentActiveMaterial.calibratedResult;
                } else {
                    this.ocrResult = {};
                }
                this.$refs.carouselEl.next();
            }
        },
        subsetChange(items) {
            this.ocrRecognitionConfirm();
            this.currentActiveId = items.id;
            this.currentActiveMaterial = items;
            this.thumbnailActiveMaterialParentTypeName = items.materialParentTypeName;
            this.initialIndex = this.imgUrlList.findIndex((item) => item.id === this.currentActiveId);
            if (this.imgUrlList[this.initialIndex].calibratedResult) {
                this.ocrResult = this.imgUrlList[this.initialIndex].calibratedResult;
            } else {
                this.ocrResult = {};
            }
            this.$refs.carouselEl && this.$refs.carouselEl.setActiveItem(this.initialIndex);
        },

        // ocr 识别
        ocrRecognitionSubmit() {
            if (this.currentActiveMaterial.originResult) {
                this.ocrResult = this.originOcrResult.find((item1) => item1.materialHash === this.currentActiveMaterial.materialHash);
                this.currentActiveMaterial.originResult = this.ocrResult;
            } else {
                this.forceOcrRecognitionSubmit();
            }
            this.alreadyOcrList.push(this.currentActiveMaterial);
            // 对 alreadyOcrList 去重
            let uniqueArray = this.alreadyOcrList.reduce((acc, cur) => {
                if (!acc[cur.id]) {
                    acc[cur.id] = cur;
                }
                return acc;
            }, {});
            this.alreadyOcrList = Object.values(uniqueArray);
        },

        // 强制ocr 识别
        forceOcrRecognitionSubmit() {
            this.ocrRecognitionLoading = true;
            this.instance.post('/tpa/api/medicine/outbound/ocr/force/' + this.currentActiveId, {}).then((res) => {
                this.ocrRecognitionLoading = false;
                let data = res.data;
                if (data.code === 200 && data.data) {
                    this.ocrResult = data.data;
                } else {
                    this.$message.error('识别无结果');
                }
            });

            this.alreadyOcrList.push(this.currentActiveMaterial);
            // 对 alreadyOcrList 去重
            let uniqueArray = this.alreadyOcrList.reduce((acc, cur) => {
                if (!acc[cur.id]) {
                    acc[cur.id] = cur;
                }
                return acc;
            }, {});
            this.alreadyOcrList = Object.values(uniqueArray);
        },

        updateOcrResult(row) {},

        ocrRecognitionConfirm() {
            let materialHash = this.imgUrlList.find((item) => item.id === this.currentActiveId).materialHash;
            if (this.ocrResult && this.ocrResult.materialOssPath && this.ocrResult.detailList && this.ocrResult.detailList.length > 0) {
                let isExist = this.calibratedOcrResult.find((item) => item.materialHash === materialHash);
                if (!isExist) {
                    if (this.ocrResult.materialOssPath) {
                        this.ocrResult.url = this.OSSClient.signatureUrl(this.ocrResult.materialOssPath);
                    }
                    if (this.ocrResult.detailList && this.ocrResult.detailList.length > 0) {
                        this.ocrResult.detailList.forEach((item) => {
                            let param = {
                                approvalNumber: item.approvalNumber,
                                dosageForm: item.dosageForm,
                                manufacturer: item.manufacturer,
                                medicineName: item.medicineName,
                                specification: item.specification
                            };
                            this.medicineForm.condition = param;

                            this.instance.post('/tpa/api/medicine/repository/precise', this.medicineForm).then((res) => {
                                let data = res.data;
                                if (data.code === 200 && data.data.list) {
                                    let medicineRepositoryData = data.data;
                                    if (medicineRepositoryData.length === 1) {
                                        if (!item.price) {
                                            item.price = medicineRepositoryData[0].price;
                                        }
                                        item.medicineProperty = medicineRepositoryData[0].medicineProperty;
                                        item.medicineType = medicineRepositoryData[0].medicineType;
                                        item.approvalNumber = medicineRepositoryData[0].approvalNumber;
                                        item.manufacturer = medicineRepositoryData[0].manufacturer;
                                        item.specification = medicineRepositoryData[0].specification;
                                        item.medicineName = medicineRepositoryData[0].medicineName;
                                        item.dosageForm = medicineRepositoryData[0].dosageForm;
                                        item.isDb = true;
                                    }
                                }
                            });
                        });
                    }

                    this.ocrResult.materialHash = materialHash;
                    this.calibratedOcrResult.push(this.ocrResult);
                } else {
                    let index = this.calibratedOcrResult.findIndex((item) => item.materialHash === materialHash);
                    this.ocrResult.materialHash = materialHash;
                    this.calibratedOcrResult[index] = this.ocrResult;
                }
                this.imgUrlList.forEach((item) => {
                    if (item.materialHash === materialHash) {
                        item.calibratedResult = this.ocrResult;
                    }
                });
            }
            let uniqueArray = this.calibratedOcrResult.reduce((acc, cur) => {
                if (!acc[cur.materialHash]) {
                    acc[cur.materialHash] = cur;
                }
                return acc;
            }, {});
            this.calibratedOcrResult = Object.values(uniqueArray);
        },

        isSubset(subset, array, prop) {
            return subset.every((subItem) => array.some((item) => this.isEqual(subItem, item, prop)));
        },

        isEqual(obj1, obj2, prop) {
            return obj1[prop] === obj2[prop];
        },

        // 退出ocr识别
        ocrRecognitionQuit() {
            let cancel = this.imgUrlList.filter((item) => item.materialType === 308 && !item.calibratedResult);
            if (cancel && cancel.length > 0) {
                let subset = this.isSubset(cancel, this.alreadyOcrList, 'id');
                if (subset === false) {
                    this.$confirm('还有未识别的出库单, 是否继续退出?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        closeOnClickModal: false,
                        type: 'warning'
                    })
                        .then(() => {
                            if (!this.ocrResult) {
                                this.medicineOutboundOcrProup = false;
                                return;
                            }
                            this.ocrRecognitionConfirm();
                            this.ocrResult = {};
                            this.medicineOutboundOcrProup = false;
                        })
                        .catch(() => {
                            this.medicineOutboundOcrProup = true;
                        });
                } else {
                    this.medicineOutboundOcrProup = false;
                }
            } else {
                this.medicineOutboundOcrProup = false;
            }
        },

        processOutboundCalibratedOcr(caseNo) {
            this.instance.get('/tpa/api/medicine/outbound/ocr/calibratedByCaseNo/' + caseNo, {}).then((res) => {
                let data = res.data;
                if (data.code === 200 && data.data) {
                    this.calibratedOcrResult = data.data;
                    this.calibratedOcrResult.forEach((item) => {
                        if (item.materialOssPath) {
                            item.url = this.OSSClient.signatureUrl(item.materialOssPath);
                        }
                        item.detailList.forEach((item1) => {
                            item1.isDb = false;
                        });
                        this.calibratedOcrResultImgs.push(item.url);
                    });
                }
            });
        },

        processOutboundOriginOcr(caseNo) {
            this.instance.get('/tpa/api/medicine/outbound/origin/ocr/' + caseNo, {}).then((res) => {
                let data = res.data;
                if (data.code === 200 && data.data) {
                    this.originOcrResult = data.data;
                    this.originOcrResult.forEach((item) => {
                        if (item.materialOssPath) {
                            item.url = this.OSSClient.signatureUrl(item.materialOssPath);
                        }
                    });
                }
            });
        },

        // 查询药品库中药品
        handleClick(row) {
            this.parentSelectedRow = row;
            this.medicineRepositoryLoading = true;
            let param = {
                approvalNumber: row.approvalNumber,
                dosageForm: row.dosageForm,
                manufacturer: row.manufacturer,
                medicineName: row.medicineName,
                specification: row.specification
            };

            if (row.approvalNumber) {
                this.differentColorColumns.push('approvalNumber');
            }
            if (row.dosageForm) {
                this.differentColorColumns.push('dosageForm');
            }
            if (row.manufacturer) {
                this.differentColorColumns.push('manufacturer');
            }
            if (row.medicineName) {
                this.differentColorColumns.push('medicineName');
            }
            if (row.specification) {
                this.differentColorColumns.push('specification');
            }

            this.medicineForm.condition = param;
            this.medicineRepositoryProup = true;
            this.instance.post('/tpa/api/medicine/repository/precise', this.medicineForm).then((res) => {
                let data = res.data;
                if (data.code === 200 && data.data.list) {
                    this.medicineRepositoryData = data.data;
                    this.medicineRepositoryData.list.forEach((item) => {
                        item.selected = false;
                    });
                } else {
                    this.medicineRepositoryData = [];
                }
                this.medicineRepositoryLoading = false;
                this.medicineForm.condition = {
                    isCalibration: 1
                };
            });
        },

        // 保存药品到药品库中
        ocrRecognitionSave() {
            this.tabActiveMaterialsLoading = true;
            this.calibratedOcrResult.forEach((item) => {
                item.caseNo = this.caseDetail.caseNo;
            });
            this.instance.post('/tpa/api/medicine/outbound/ocr/calibrated/save', this.calibratedOcrResult).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    this.$message.success('保存成功');
                } else {
                    this.$message.error(data.message);
                }
                this.tabActiveMaterialsLoading = false;
            });
        },

        handleMedicineSelectionChange(val) {
            if (this.selectedRow) {
                this.$refs.medicineMultipleTable.toggleRowSelection(this.selectedRow, false);
            }
            if (val) {
                val.filter((item) => item !== this.selectedRow);
                this.selectedRow = val[val.length - 1];
            }
        },

        // 处理选择的药品数据
        handleSelectMedicine() {
            let currentActiveTab = this.calibratedOcrResult[this.tabActiveMaterialsId];
            if (currentActiveTab) {
                let detailList = currentActiveTab.detailList;
                let childIndex = detailList.findIndex((item) => item === this.parentSelectedRow);
                if (this.parentSelectedRow.price) {
                    this.selectedRow.price = this.parentSelectedRow.price;
                }
                if (this.parentSelectedRow.amount) {
                    this.selectedRow.amount = this.parentSelectedRow.amount;
                }
                if (this.parentSelectedRow.number) {
                    this.selectedRow.number = this.parentSelectedRow.number;
                }
                this.parentSelectedRow = this.selectedRow;
                detailList[childIndex] = this.selectedRow;
                currentActiveTab.detailList = detailList;
                this.calibratedOcrResult[this.tabActiveMaterialsId] = currentActiveTab;
                this.$set(this.calibratedOcrResult[this.tabActiveMaterialsId].detailList, childIndex, this.parentSelectedRow);
            }
            this.medicineRepositoryProup = false;
        },

        handleTabClick(tab, event) {
            // this.tabActiveMaterialsId = tab.;
        },

        // 处理多行选择
        handleMultipleRowClick(val) {
            this.selectedRows = val;
        },
        multipleRowClassName({ row }) {
            return this.selectedRows.find((item) => item === row) ? 'row-selected' : '';
        },

        // 处理多行选择后使用的数据
        handleMultipleSelectMedicine() {
            let detailList = [];
            this.selectedRows.forEach((item) => {
                detailList.push({
                    approvalNumber: item.approvalNumber,
                    dosageForm: item.medicineCharacter,
                    manufacturer: item.manufacturer,
                    medicineName: item.medicineName,
                    specification: item.specification,
                    price: item.price,
                    medicineType: item.medicineType,
                    medicineProperty: item.medicineProperty
                });
            });
            let dbSelectTable = this.calibratedOcrResult.find((item) => item.materialId === 0);

            if (dbSelectTable) {
                let activeRabIndex = this.calibratedOcrResult.findIndex((item) => item.materialId === 0);
                detailList.forEach((item) => {
                    dbSelectTable.detailList.push(item);
                });
                this.calibratedOcrResult[activeRabIndex] = dbSelectTable;
            } else {
                this.calibratedOcrResult.unshift({
                    materialId: 0,
                    totalReceivable: null,
                    caseNo: this.caseDetail.caseNo,
                    detailList: detailList
                });
            }
            this.tabActiveMaterialsId = '0';
            this.handleTabClick();
            this.goodsVisible = false;
        },

        // 删除行
        handleDelete(row) {
            let currentActiveTab = this.calibratedOcrResult[this.tabActiveMaterialsId];
            if (currentActiveTab) {
                let detailList = currentActiveTab.detailList;
                let childIndex = detailList.findIndex((item) => item === row);
                detailList.splice(childIndex, 1);
                currentActiveTab.detailList = detailList;
                this.calibratedOcrResult[this.tabActiveMaterialsId] = currentActiveTab;
            }
            this.medicineRepositoryProup = false;
        },

        // 处理药品库查询表头样式
        medicineRepositoryProupHeader({ row, column, rowIndex, columnIndex }) {
            if (this.differentColorColumns.includes(column.property)) {
                return 'background-color: #7bc790;color: #fff;';
            }
        },

        //
        medicineRepositoryProupCell({ row, column, rowIndex, columnIndex }) {
            if (this.differentColorColumns.includes(column.property)) {
                return 'background-color: #7bc790;color: #fff;';
            }
        },
        showRiskPreviewDialog(riskCardIndex, riskCardList, currentRiskItem, currentRiskIndex) {
            this.previewShow = true;
            this.$nextTick(() => {
                this.$refs.previewRiskPopup && this.$refs.previewRiskPopup.switchRiskImg(riskCardList, currentRiskItem, currentRiskIndex, riskCardIndex);
            });
        },
        splitPopup(flag) {
            // 需要移动的元素
            // 案件风险 claimant
            var sourceEl = document.getElementById('loss_assessment');
            // 商品信息 productInfo
            var productInfoEl = document.getElementById('productInfo');
            // 出货单信息 cardAdjument
            var cardAdjumentEl = document.getElementById('cardAdjument');
            // 商品损失信息 productLossInfo
            var productLossInfoEl = document.getElementById('productLossInfo');
            // 定损风险 LossAssessmentRisk
            var LossAssessmentRiskEl = document.getElementById('LossAssessmentRisk');
            // 其他费用 otherExpenses
            var otherExpensesEl = document.getElementById('otherExpenses');

            // 公共元素
            var targetLeftEl = document.getElementById('targetLeft');
            var targetRightEl = document.getElementById('targetRight');
            var claimantEl = document.getElementById('claimant');
            if (flag) {
                // 分屏左侧
                sourceEl.removeChild(claimantEl);
                targetLeftEl.appendChild(claimantEl);

                // 分屏右侧
                sourceEl.removeChild(productInfoEl);
                targetRightEl.appendChild(productInfoEl);
                if (cardAdjumentEl) {
                    sourceEl.removeChild(cardAdjumentEl);
                    targetRightEl.appendChild(cardAdjumentEl);
                }
                sourceEl.removeChild(productLossInfoEl);
                targetRightEl.appendChild(productLossInfoEl);
                sourceEl.removeChild(LossAssessmentRiskEl);
                targetRightEl.appendChild(LossAssessmentRiskEl);
                sourceEl.removeChild(otherExpensesEl);
                targetRightEl.appendChild(otherExpensesEl);

                this.showPopup = flag;
            } else {
                // 分屏左侧
                targetLeftEl.removeChild(claimantEl);
                sourceEl.appendChild(claimantEl);

                // 分屏右侧
                targetRightEl.removeChild(productInfoEl);
                sourceEl.appendChild(productInfoEl);
                if (cardAdjumentEl) {
                    targetRightEl.removeChild(cardAdjumentEl);
                    sourceEl.appendChild(cardAdjumentEl);
                }
                targetRightEl.removeChild(productLossInfoEl);
                sourceEl.appendChild(productLossInfoEl);
                targetRightEl.removeChild(LossAssessmentRiskEl);
                sourceEl.appendChild(LossAssessmentRiskEl);
                targetRightEl.removeChild(otherExpensesEl);
                sourceEl.appendChild(otherExpensesEl);
                this.showPopup = flag;
            }
        },
        switchChange(flag, rowItem, rowIndex, str) {
            if (flag) {
                rowItem[str] = 0;
                this.$forceUpdate();
                this.lossCalculation();
            }
        },
        lookReason() {
            this.getSuggestPredict(this.caseDetail.caseNo);
            setTimeout(() => {
                this.reasonPopupFlag = true;
            }, 500);
        },
        // 减赔原因
        getSuggestPredict(caseNo) {
            this.reduceReasonScript = [];
            this.instance.get('/tpa/api/case/negotiate/suggest/predict/' + caseNo, {}).then((res) => {
                let data = res.data;
                if (data.code == 200 && data.data) {
                    data.data.forEach((item, index) => {
                        // 减赔原因
                        if (item.reduceReasonScript != '无' && item.reduceReasonScript) {
                            this.reduceReasonScript.push(item.reduceReasonScript);
                        }
                    });
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        radioInput(val) {
            if ((val == 1 && this.allPriceTableData1.length < 1) || (val == 2 && this.allPriceTableData2.length < 1)) {
                this.getGoodsList();
            }
        },
        showModelInfo(typeStr) {
            // 损失单价 - 跳转到价值证明有单价的那张

            let imgHash;
            // 索赔数量、损伤程度、损失比例 跳转内物第一张图
            if (typeStr == 'claimNum' || typeStr == 'injureType' || typeStr == 'lossRate') {
                if (Object.keys(this.modelData.damageMap).length > 0) {
                    for (var key in this.modelData.damageMap) {
                        imgHash = key;
                        break;
                    }
                }
                imgHash && this.$refs.claimant.findImgHashActive(imgHash);
                return;
            }

            if (typeStr == 'price') {
                imgHash = this.modelData.modelData.checksum[0];
                imgHash && this.$refs.claimant.findImgHashActive(imgHash);
            }
        },
        goBackData() {
            this.carPartFlag = false;
            setTimeout(() => {
                this.structureList = [];
            }, 200);
        },
        showCarDialog(showFlag) {
            if (showFlag) {
                this.searchCarForm = {
                    cascader: '',
                    year: ''
                };
                this.provinceVal = null;
                this.selectTableObj = {};
                this.partPrice = null;
                this.selectedParts = [];
                this.resultCarList = [];
                this.carPartFlag = false;
                this.queryCarInfoFlag = true;

                this.carVisible = true;
                this.switchGoodsDialog(!showFlag);
            } else {
                this.medicineProup = true;
                setTimeout(() => {
                    this.carVisible = false;
                }, 50);
            }
        },
        switchGoodsDialog(showFlag) {
            setTimeout(() => {
                this.goodsVisible = showFlag;
            }, 50);
        },
        currentRowChange(val) {
            this.carPartFlag = true;

            this.searchInfoForm = {
                amVehicleId: val.amVehicleId,
                partGroupId: null,
                oeOrPartName: null,
                queryType: null
            };
            this.structureList = [];
            this.carPartList = [];
            this.selectedParts = [];
            this.selectTableObj = {};
            this.tmpMenuStr = '';
            this.activeMenuStr = '';
            this.partPrice = null;
            this.toggleSelection();

            this.getVehicleStructure(val.amVehicleId);
        },
        handleSelectCost(val) {
            // console.log(this.$refs['cascader'].inputValue);
            if (val && val.length > 0) {
                this.getSalesModelsInfo(val[val.length - 1]);
            } else {
                this.queryCarInfoFlag = true;
            }
        },
        // 品牌
        getBrandInfo() {
            cascCaderOptions = [];
            this.instance.get('/tpa/api/vehicle/getBrandInfo', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 200 && data.data) {
                    data.data.forEach((item) => {
                        item.value = item.amMainBrandId;
                        item.label = item.amMainBrandName;
                        if (item.amBrandList) {
                            item.amBrandList.forEach((childItem) => {
                                childItem.value = childItem.amBrandId;
                                childItem.label = childItem.amBrandName;
                            });
                            item.children = item.amBrandList;
                        }
                    });
                    cascCaderOptions = data.data;
                }
            });
        },
        // 车系
        getSeriesInfo(id) {
            return new Promise((resolve, reject) => {
                this.instance.get('/tpa/api/vehicle/getSeriesInfo', { params: { amBrandId: id } }).then((res) => {
                    let data = res.data;
                    if (data.code == 200) {
                        data.data.forEach((item) => {
                            item.value = item.amSeriesId;
                            item.label = item.amSeriesName;
                            item.leaf = true;
                        });
                        resolve(data.data);
                    }
                });
            });
        },
        // 车型
        getSalesModelsInfo(id) {
            this.yearOptions = [];
            this.carList = [];
            this.queryCarInfoFlag = true;
            this.yearLoading = true;
            this.searchCarForm.year = null;
            this.resultCarList = [];
            this.instance.get('/tpa/api/vehicle/getSalesModelsInfo', { params: { amSeriesId: id } }).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    let yearArr = [];
                    data.data.forEach((item) => {
                        yearArr.push(item.amYear);
                    });
                    this.carList = data.data;
                    this.yearOptions = [...new Set(yearArr)];
                    this.yearOptions = this.yearOptions.sort((a, b) => b - a);
                    this.queryCarInfoFlag = false;
                    this.yearLoading = false;
                }
            });
        },
        searchCar() {
            if (!this.provinceVal) {
                this.$message.error('请选择省份！');
                return;
            }
            if (!this.searchCarForm.cascader) {
                this.$message.error('请选择车型！');
                return;
            }
            if (!this.searchCarForm.year) {
                this.$message.error('请选择年份！');
                return;
            }
            let tmpResult = [];
            this.carList.forEach((carItem) => {
                if (carItem.amYear == this.searchCarForm.year) {
                    tmpResult.push(carItem);
                }
            });
            this.resultCarList = tmpResult;
        },
        searchCarPart(rowItem, searchFlag, activeStr) {
            if (searchFlag && !this.searchInfoForm.oeOrPartName) {
                this.$message.error('请输入零件名称/OE码！');
                return;
            }

            // 点击菜单
            this.activeMenuStr = activeStr;
            this.tmpMenuStr = '';

            this.getPartsList(rowItem);
        },
        // 车辆结构
        getVehicleStructure(amModelId) {
            this.instance.get('/tpa/api/vehicle/getVehicleStructure', { params: { amModelId: amModelId } }).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    let menusData = [];
                    data.data.forEach((val, key) => {
                        val.child = [];
                        if (val.partGroupLevel == 1) {
                            menusData.push(val);
                        }
                    });
                    data.data.forEach((val, key) => {
                        if (val.partGroupLevel == 2) {
                            menusData.forEach((value, index) => {
                                if (value.partGroupId == val.parentId) {
                                    value.child.push(val);
                                }
                            });
                        }
                    });
                    data.data.forEach((val, key) => {
                        if (val.partGroupLevel == 3) {
                            menusData.forEach((value, index) => {
                                for (let i = 0, len = value.child.length; i < len; i++) {
                                    if (value.child[i].partGroupId == val.parentId) {
                                        value.child[i].child.push(val);
                                    }
                                }
                            });
                        }
                    });

                    this.structureList = menusData;
                }
            });
        },
        // 零件列表
        getPartsList(rowItem) {
            if (rowItem) {
                this.searchInfoForm.partGroupId = rowItem.partGroupId;
                this.searchInfoForm.oeOrPartName = rowItem.partGroupName;
                this.searchInfoForm.queryType = 2;
            }
            this.carPartLoading = true;
            this.searchInfoForm.queryType = this.startsWithChinese(this.searchInfoForm.oeOrPartName) ? 2 : 1;
            this.instance.post('/tpa/api/vehicle/getPartsList', this.searchInfoForm).then((res) => {
                this.carPartLoading = false;
                let data = res.data;
                if (data.code == 200) {
                    let tmpVal = this.provinceVal.split('-')[1];
                    data.data &&
                        data.data.forEach((item) => {
                            item.tmpPrice = item.partsPriceDto[tmpVal];
                            item.guidePrice = item.partsPriceDto.guidePrice;
                        });
                    this.carPartList = data.data;

                    // 回显选择数据
                    let tmpSlectObj = JSON.parse(JSON.stringify(this.selectTableObj));
                    let selectData = tmpSlectObj[this.activeMenuStr];
                    if (selectData) {
                        let setSelectArr = [];
                        this.carPartList.forEach((listItem) => {
                            selectData.forEach((dataItem) => {
                                if (listItem.oeId == dataItem.oeId) {
                                    setSelectArr.push(listItem);
                                }
                            });
                        });

                        setTimeout(() => {
                            this.toggleSelection(setSelectArr);
                        }, 200);
                    }
                }
            });
        },
        startsWithChinese(str) {
            if (!str || str.length === 0) {
                return false;
            }

            const firstCharCode = str.charCodeAt(0);
            return firstCharCode >= 0x4e00 && firstCharCode <= 0x9fff;
        },
        handleSelectionChange(val) {
            if (!this.tmpMenuStr && val.length == 0) {
            } else {
                this.selectTableObj[this.activeMenuStr] = val;

                let rowArr = [];
                for (const key in this.selectTableObj) {
                    const subArray = this.selectTableObj[key];
                    const newSubArray = subArray.map((item) => item);
                    rowArr.push(...newSubArray);
                }
                this.selectedParts = rowArr;
            }

            this.calculatePrice();
        },
        calculatePrice() {
            this.tmpMenuStr = JSON.parse(JSON.stringify(this.activeMenuStr));

            let tmpPrice = 0;
            this.selectedParts.forEach((priceItem) => {
                if (priceItem.tmpPrice) {
                    tmpPrice += Number(priceItem.tmpPrice) * 100000;
                }
            });

            if (tmpPrice >= 0) {
                this.partPrice = tmpPrice / 100000;
            }
        },
        usePartPrice() {
            if (this.selectedParts.length < 1) {
                this.$message.error('请选择配件！');
                return;
            }

            this.tmpProductItem.row.price = this.partPrice;
            let tmpRow = this.tmpProductItem.row;
            let tmpIndex = this.tmpProductItem.rowIndex;
            this.$set(this.caseDetail.goodsInfo, tmpIndex, tmpRow);
            let tmpList = JSON.parse(JSON.stringify(this.caseDetail.goodsInfo));
            this.caseDetail.goodsDtoList = tmpList;
            // 商品数量联动
            this.goodsNumLinkage(this.tmpProductItem.row.price);
            // 损失单价改变计算
            this.priceChange(tmpRow, tmpIndex);
            this.carVisible = false;
            this.goodsVisible = false;
        },
        getProvince() {
            for (const key in this.provinceObj) {
                const subArray = this.provinceObj[key];
                const newSubArray = subArray.map((item) => ({ value: item }));
                this.provinceOptions.push(...newSubArray);
            }
        },
        toggleSelection(rows) {
            if (rows) {
                rows.forEach((row) => {
                    this.$refs.vehicleTable.toggleRowSelection(row, true);
                });
            } else {
                this.$refs.vehicleTable.clearSelection();
            }
        }
    },
    updated() {
        if (!this.lossUpdateStyle) {
            setTimeout(() => {
                this.setTxtOverFlowStyle(1);
            }, 500);
        }
    },
    destroyed() {
        localStorage.removeItem('editTotalAmount');
        localStorage.removeItem('editConfirmAmount');
    },
    computed: {
        imgStyle() {
            return {
                transform: `translate3d(${this.imgDistance}px, 0, 0)` // 计算移动的距离(x,y,z)
            };
        }
    },
    watch: {
        caseDetail(data) {
            if (data.caseNo) {
                // 赋值蓝色可点击数字
                // 400以内的金额(理赔助手)展示蓝色回显文字
                this.setPredictVal();
                // 获取大模型分析结果 赋值蓝色可点击数字
                if (data.claimAmount && data.claimAmount >= 100) {
                    this.setModelVal();
                }

                this.getAdjust();
                this.getAdjustRiskInfo();
                this.getMedicineProperty();
                this.getMedicineType();
                this.combGoodsAmount(data);
                this.processOutboundCalibratedOcr(data.caseNo);
                this.updateCalculateAmount(data.caseNo);
                this.goodsCategory = data.goodsInfo[0].goodsCategory;
                // 减赔原因
                this.getSuggestPredict(data.caseNo);

                // 商品询价-商品数量
                this.goodsNum = data.goodsInfo[0].totalNum || 1;
            }
        },
        suspensionBtnObj: {
            handler(val) {
                if (val && val.tmpHandle) {
                    this.isEditInfo = val.tmpHandle.subscriptShow ? false : true;
                } else if (localStorage.getItem('detailShow')) {
                    this.isEditInfo = localStorage.getItem('detailShow') == 'show' ? false : true;
                } else {
                    // 41,59,75,70,50,90, 待核赔 49
                    let noEditCaseCode = [41, 59, 75, 70, 50, 90, 49];
                    let codeIndex = -1;
                    if (val && val.tmpStep && val.tmpStep.code) {
                        codeIndex = noEditCaseCode.indexOf(val.tmpStep.code);
                    }
                    this.isEditInfo = codeIndex == -1;
                }
            },
            deep: true
        },
        tmpOSSClient: {
            handler(val) {
                if (val) {
                    // console.log('lossAssessment');
                    // console.log(val);
                    this.OSSClient = val;
                }
            },
            immediate: true
        },
        elementShow: {
            handler(val) {},
            immediate: true
        }
    }
};


import OSS from 'ali-oss';
import ImgDeal from '@/components/ImgDeal.vue';
import Claimant from './Claimant.vue';
import CaseRisk from './CaseRisk.vue';
import CaseLog from './CaseLog.vue';
import LossAssessment from './LossAssessment.vue';
import CaseLoss from './CaseLoss.vue';
import GuaranteeSlip from './GuaranteeSlip.vue';
import CaseStep from './CaseStep.vue';
import SuspendNav from './suspendNav.vue';

export default {
    name: 'myTask',
    props: {
        handleData: {
            type: Object,
            default: {}
        },
        OSSClient: {
            type: Object,
            default: null
        }
    },
    components: {
        ImgDeal,
        Claimant,
        CaseRisk,
        CaseLog,
        LossAssessment,
        GuaranteeSlip,
        CaseLoss,
        CaseStep,
        SuspendNav
    },
    data() {
        return {
            tanFlag: false,
            scrollFlag: false,
            hideCaseLog: false,
            caseDetail: {
                caseRiskVos: []
            },
            allPriceVisible: false,
            drawer: false,
            caseLoading: false,
            checkedCase: {},
            caseDetail: {},
            goodsModeForm: {
                processMethod: 10,
                otherExpenses: []
            },
            damagedProcessMethod: [],
            damagedDegreeOption: [],
            suspensionBtnObj: {
                sort: 1,
                step: '立案',
                tmpStep: {}
            },
            searchInput: '',
            submitDialogVisible: false,
            submitRuleForm: {
                action: 'APPROVE',
                reason: '',
                remark: '',
                risk: '',
                remark1: ''
            },
            submitRules: {
                resource: [{ required: true, message: '请输入内容', trigger: 'change' }],
                region: [{ required: true, message: '请输入内容', trigger: 'change' }],
                remark1: [{ required: true, message: '请输入内容', trigger: 'change' }],
                remark: [{ required: true, message: '请输入内容', trigger: 'change' }],
                risk: [{ required: true, message: '请输入内容', trigger: 'change' }]
            },
            radioGroupList: [
                {
                    value: 'APPROVE',
                    label: '通过'
                },
                {
                    value: 'REJECT',
                    label: '不通过'
                }
            ],
            reasonOptionList: [],
            isBtnShow: false,
            submitDisabled: false,
            tmpOSSClient: null,
            disappearFlag: false,
            detailHeight: '300px',
            suspensionList: [],
            detailsRadio: '0',
            detailsRadioArr: [
                {
                    label: '案件定损'
                },
                {
                    label: '定损结果'
                },
                {
                    label: '保单信息'
                }
                // {
                //     label: '索赔信息'
                // },
                // {
                //     label: '案件风险'
                // },
            ],
            rhetoricFlag: true,
            standardTypeOptions: [
                {
                    label: '产品定价建议'
                },
                {
                    label: '谈赔要点'
                },
                {
                    label: '风险点'
                }
            ],
            phrasesVisible: false,
            preClaimStandardList: [],
            sourceList: [],
            phrasesForm: {
                standardType: '',
                standard: ''
            },
            phrasesRules: {
                standardType: [{ required: true, message: '请选择类别', trigger: 'blur' }],
                standard: [{ required: true, message: '请输入具体话术', trigger: 'blur' }]
            },
            resetOssNum: 0,
            popupTitle: '新增话术',
            mergeData: []
        };
    },
    mounted() {
        this.initOSSClient();
        // 预报案角标点击进入，subscriptShow=true不展示提交按钮。再执行detailShow判断
        this.isBtnShow = this.handleData.subscriptShow ? false : this.handleData.detailShow != 'detailShow';

        // 动态计算table高度
        this.calcHeight();
        window.onresize = () => {
            this.calcHeight();
        };
        // 绑定卡片tab 与 卡片 关联关系
        this.detailsRadioArr.forEach((item, index) => {
            item.code = index;
        });
        // 获取损失处理方式枚举
        this.getPublicEnum('getDamagedProcessMethodEnum', 'damagedProcessMethod');
        // 获取其他费用选项
        this.getOtherSelect();
        // 获取损失类型
        this.getDamagedDegreeEnum();
        this.getPreClaimStandard();
    },
    methods: {
        getPreClaimStandard() {
            this.sourceList = [];
            this.instance.get('/tpa/api/preClaimStandard/get', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 200 && data.data) {
                    let tmpList = [];
                    let tmpObj = {};
                    var keys = Object.keys(data.data).sort();
                    keys.forEach((item) => {
                        tmpObj = {
                            title: item,
                            list: data.data[item]
                        };
                        tmpList.push(tmpObj);
                    });

                    let mergedArray = Object.values(data.data).flat();
                    this.mergeData = mergedArray;

                    this.sourceList = data.data;
                    this.preClaimStandardList = tmpList;
                }
            });
        },
        handleScroll(e) {
            // this.scrollFlag = e.target.scrollTop >= 130;
        },
        cardScroll(e) {
            // if (e.target.scrollTop <= 5) {
            //     this.scrollFlag = false;
            //     console.log(this.scrollFlag);
            // }
        },
        hiddenCaseLog() {
            this.hideCaseLog = !this.hideCaseLog;
        },
        saveForm() {
            localStorage.setItem(String(this.caseDetail.caseNo), JSON.stringify(this.submitRuleForm));
        },
        calcHeight() {
            let registerTabsHeight = document.querySelector('.register_tabs') ? document.querySelector('.register_tabs').clientHeight : 0;
            let caseStepHeight = document.querySelector('.case-step') ? document.querySelector('.case-step').clientHeight : 0;
            let guHeight = 55 - 5 + 65;
            this.detailHeight = window.innerHeight - registerTabsHeight - caseStepHeight - guHeight + 'px';
        },
        // 初始化OSS
        initOSSClient() {
            this.tmpOSSClient = null;
            this.resetOssNum++;
            this.instance
                .get('/tpa/api/aliyun/oss/access')
                .then((res) => {
                    let data = res.data;
                    if (data.code == 200) {
                        console.log('oss初始化成功');
                        this.tmpOSSClient = new OSS({
                            accessKeyId: data.data.accessKeyId,
                            accessKeySecret: data.data.accessKeySecret,
                            stsToken: data.data.securityToken,
                            // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou。
                            region: data.data.region,
                            bucket: data.data.bucket
                        });
                    } else {
                        console.log('oss初始化失败，正在重试！');

                        if (this.resetOssNum < 20) {
                            setTimeout(() => {
                                this.initOSSClient();
                            }, 1000);
                        } else {
                            console.log('oss初始化超过20s，失败!');
                        }
                    }
                })
                .catch((err) => {
                    console.log('oss初始化失败-');
                    console.log(err);
                });
        },
        closeSubmitDialog(saveFlag) {
            this.submitDialogVisible = false;
            // saveFlag == 'true' && localStorage.removeItem(String(this.caseDetail.caseNo))
            this.$refs.ruleForm.resetFields();
        },
        closeCaseDetail(data) {
            this.$emit('closeCaseDetail', data);
        },
        handleClick(tabIndex) {
            let idx = this.$el.querySelectorAll('.case_card');
            idx.forEach((item, index) => {
                if (tabIndex == index) {
                    item.scrollIntoView({
                        alignToTop: false,
                        behavior: 'smooth',
                        block: 'start'
                    });
                }
            });
        },
        handleRadioGroup(data) {
            this.suspensionBtnObj = data ? data : {};
            if (data && data.step == '核赔') {
                this.radioGroupList = [
                    {
                        value: 'APPROVE',
                        label: '通过'
                    }
                ];
            }

            if (data && data.step == '定损') {
                this.radioGroupList = [
                    {
                        value: 'APPROVE',
                        label: '通过'
                    },
                    {
                        value: 'REJECT',
                        label: '补充材料'
                    }
                ];
            }
        },
        // 定损计算
        lossCalculation() {
            let goodsTotalAmount = '1000';
            let detailAmount = '';
            let tmpGoodsDtoList = this.caseDetail.goodsDtoList;
            tmpGoodsDtoList &&
                tmpGoodsDtoList.forEach((item, index) => {
                    detailAmount += index != tmpGoodsDtoList.length - 1 ? item.adjustAmount + '+' : item.adjustAmount;
                });
            this.caseDetail.tmpTotalAmount = detailAmount.length > 0 ? detailAmount + ' = ' + goodsTotalAmount : '';
            this.$forceUpdate();
        },
        // 案件详情
        handleCaseList(caseNo) {
            this.allPriceVisible = false;
            this.drawer = false;
            this.caseLoading = true;
            this.caseDetail = {};
            this.adjustmentForm = this.$options.data().adjustmentForm;
            this.adjustResult = this.$options.data().adjustResult;
            this.caseEnterData = this.$options.data().caseEnterData;
            this.instance
                .get('/tpa/api/case/detail/' + caseNo, {})
                .then((res) => {
                    this.caseLoading = false;
                    if (res.data.code === 200) {
                        // todo
                        // res.data.data.relatedCaseNos = ['CASE1806225304509181952', 'CASE1806571755126644736'];
                        this.initCaseDetail(res.data.data);
                    }
                })
                .catch((res) => {
                    this.caseLoading = false;
                    console.log(res);
                    this.$message.error('请求失败, 请稍后重试');
                });
        },
        //处理案件详情信息
        initCaseDetail(response) {
            var _this = this;
            response.materialsList = response.materialsList || [];
            response.caseRiskVos = response.caseRiskVos || [];
            this.caseDetail = response;
            this.submitRuleForm.caseNo = response.caseNo;
            this.queryCaseNo = response.caseNo;

            //过滤审核风险，组合原因
            if (this.caseDetail.caseRiskVos.length > 0) {
                let arr = [];
                this.caseDetail.caseRiskVos.forEach((value) => {
                    value.highRiskInfos && (arr = arr.concat(value.highRiskInfos));
                    value.mediumRiskInfos && (arr = arr.concat(value.mediumRiskInfos));
                    value.lowRiskInfos && (arr = arr.concat(value.lowRiskInfos));

                    // 数字为null时重置为0
                    value.highCount = value.highCount && value.highCount > 0 ? value.highCount : 0;
                    value.lowCount = value.lowCount && value.lowCount > 0 ? value.lowCount : 0;
                    value.mediumCount = value.mediumCount && value.mediumCount > 0 ? value.mediumCount : 0;
                    value.riskCount = value.riskCount && value.riskCount > 0 ? value.riskCount : 0;
                });
            }

            // 右侧悬浮窗
            if (response.caseStep == 75) {
                this.suspensionList = [{ name: '索赔信息' }, { name: '案件风险' }, { name: '案件定损' }, { name: '定损结果' }, { name: '保单信息' }, { name: '案件日志' }];
            } else {
                this.suspensionList = [{ name: '索赔信息' }, { name: '案件风险' }, { name: '案件定损' }, { name: '保单信息' }, { name: '案件日志' }];
            }
        },
        // 获取其他费用选项
        getOtherSelect() {
            this.instance.get('/tpa/api/enum/getOtherExpense', {}).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.otherSelectOption = data.data;
                }
            });
        },
        editOtherList(index, btnStr) {
            if (btnStr == 'del') {
                this.goodsModeForm.otherExpenses.splice(index, 1);
            } else if (this.goodsModeForm.otherExpenses.length < 16) {
                this.goodsModeForm.otherExpenses.push({
                    code: '',
                    expenseAmount: '',
                    expenseName: ''
                });
            }

            if (this.goodsModeForm.otherExpenses.length > 15) {
                this.$message.error('其他费用，目前仅支持添加15个费用');
            }
        },
        otherSelectChange(val) {
            var tmpIndex = this.otherSelectOption.findIndex((res) => {
                return val == res.code;
            });
            tmpIndex != -1 &&
                this.goodsModeForm.otherExpenses.forEach((item) => {
                    if (item.code == val) {
                        item.expenseName = this.otherSelectOption[tmpIndex].desc;
                    }
                });
        },
        //获取受损层度
        getDamagedDegreeEnum() {
            let json = {
                caseNo: this.caseDetail.caseNo
            };
            this.instance.get('/tpa/api/enum/getDamagedDegreeEnum', { params: json }).then((res) => {
                if (res.data.code == 200) {
                    this.damagedDegreeOption = res.data.data;
                }
            });
        },
        // 提交案件按钮
        submitCase(submitFlag) {
            let _this = this;
            if (localStorage.getItem('editConfirmAmount') == 'true') {
                this.$message.error('点击了修改 “赔偿确认金额” ，但还未提交');
                return;
            }

            if (!submitFlag) {
                this.submitDialogVisible = true;
                this.rhetoricFlag = true;
                if (localStorage.getItem(String(this.caseDetail.caseNo))) {
                    this.submitRuleForm = JSON.parse(localStorage.getItem(String(this.caseDetail.caseNo)));
                }
            } else {
                this.$refs.ruleForm.validate((valid) => {
                    if (valid && !this.disappearFlag) {
                        this.submitRuleForm.reason = this.submitRuleForm.remark1 ? [this.submitRuleForm.remark1] : [this.submitRuleForm.reason];
                        var json = this.submitRuleForm;
                        json.caseNo = this.caseDetail.caseNo;
                        this.submitDisabled = true;
                        this.disappearFlag = true;
                        this.instance.post('/tpa/api/case/action', json).then((res) => {
                            this.submitDisabled = false;
                            if (res.data.code == 200) {
                                localStorage.removeItem(String(this.caseDetail.caseNo));
                                this.$message.success('提交成功');
                                setTimeout(() => {
                                    this.submitDialogVisible = false;
                                    this.$refs.ruleForm.resetFields();
                                    this.$emit('closeCaseDetail');
                                    this.disappearFlag = false;
                                }, 500);
                                this.handleCaseList(this.handleData.caseNo);
                            } else {
                                this.disappearFlag = false;
                                this.$message.error(res.data.message);
                            }
                        });
                    }
                });
            }
        },
        //通过商品名称获取商品比价
        getGoodsPriceRelations(item, index) {
            this.instance.get(`/tpa/api/goods/getGoodsPriceRelations?goodsCategory=${item.goodsCategoryName}&goodsName=${item.goodsName}`).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    data.data = {
                        averagePrice: 100
                    };
                    let tmpItem = item;
                    // tmpItem.price = data.data.averagePrice
                    tmpItem.price = 100;
                    tmpItem.subtotalPrice = 100;
                    this.$set(this.caseDetail.goodsInfo, index, tmpItem);
                }
            });
        },
        closePhrases(closeFlag) {
            if (closeFlag) {
                this.rhetoricFlag = false;
            } else {
                this.phrasesForm = {
                    standardType: '',
                    standard: ''
                };
                this.phrasesVisible = true;
                this.hideSubmitPopup();
            }
        },
        hideSubmitPopup() {
            setTimeout(() => {
                this.submitDialogVisible = false;
            }, 50);
        },
        closeDialogFn(closeFlag, fn) {
            if (closeFlag) {
                this.$refs.phrasesRef.validate((valid) => {
                    if (valid) {
                        this.popupTitle == '新增话术' && this.addPreClaimStandard();
                        this.popupTitle == '编辑话术' && this.editPreClaimStandard();
                    }
                });
            } else {
                this.phrasesVisible = false;
                this.submitDialogVisible = true;
            }
        },
        addPreClaimStandard() {
            this.instance.post('/tpa/api/preClaimStandard/add', this.phrasesForm).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.getPreClaimStandard();
                    this.$message.success('添加成功！');
                    this.phrasesVisible = false;
                    this.submitDialogVisible = true;
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        editPreClaimStandard() {
            this.instance.post('/tpa/api/preClaimStandard/update', this.phrasesForm).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.getPreClaimStandard();
                    this.$message.success('编辑成功！');
                    this.phrasesVisible = false;
                    this.submitDialogVisible = true;
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        delPreClaimStandard(id) {
            this.instance.post('/tpa/api/preClaimStandard/delete/' + id, {}).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.getPreClaimStandard();
                    this.$message.success('删除成功！');
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        phrasesActive(item, index, itemParentIndex, itemParentArr) {
            this.preClaimStandardList[itemParentIndex].list[index].active = true;
            this.$forceUpdate();
        },
        showRhetoric() {
            if (this.rhetoricFlag) {
                this.rhetoricFlag = false;
            } else {
                this.rhetoricFlag = true;
                this.getPreClaimStandard();
            }
        },
        handleTxt(handleType, txtItem, txtIndex, txtItemList) {
            if (handleType) {
                this.popupTitle = '编辑话术';
                this.phrasesForm = JSON.parse(JSON.stringify(txtItem));
                this.phrasesVisible = true;
                this.hideSubmitPopup();
            } else {
                this.$confirm('确认删除？')
                    .then((_) => {
                        this.delPreClaimStandard(txtItem.id);
                        done();
                    })
                    .catch((_) => {});
            }
        },
        searchChange(val) {
            let findArr = this.mergeData.filter((item) => item.standard.includes(val));

            let typeArr = ['产品定价建议', '谈赔要点', '风险点'];

            let resultArr = [];
            typeArr.forEach((title) => {
                let item = { title: title, list: [] };
                findArr.forEach((obj) => {
                    if (obj.standardType === title) {
                        item.list.push(obj);
                    }
                });
                item.list.length > 0 && resultArr.push(item);
            });

            this.preClaimStandardList = resultArr;
        }
    },
    watch: {
        handleData: {
            handler(val) {
                this.isBtnShow = localStorage.getItem('detailShow') == 'show' ? false : true;
                val.caseNo && this.handleCaseList(this.handleData.caseNo);
                this.calcHeight();
            },
            immediate: true
        }
    }
};


export default {
    name: 'CaseLog',
    props: {
        caseDetail: {
            typeof: Object,
            default: {}
        }
    },
    data() {
        return {
            reason: null,
            remark: null,
            risk: null,
            adjustmentFormula: null,
            relAdjustList: null,
            activeNames: [0, 1, 2]
        }
    },
    methods: {
        //获取案件核赔信息
        getAdjust() {
            this.instance.get('/tpa/api/case/enter/adjust/getAdjust?caseNo=' + this.caseDetail.caseNo).then((res) => {
                let data = res.data
                if (data.code == 200 && data.data) {
                    this.risk = data.data.risk
                    this.reason = data.data.reason
                    this.remark = data.data.remark
                    this.adjustmentFormula = data.data.adjustmentFormula
                    this.$forceUpdate()
                }
            })
        },
        //获取预报案案件理算信息
        getRelAdjust() {
            this.relAdjustList = []
            this.instance.get('/tpa/api/case/enter/adjust/getRelAdjust/' + this.caseDetail.caseNo).then((res) => {
                let data = res.data
                if (data.code == 200 && data.data) {
                    data.data.forEach((item, index) => {
                        item && this.relAdjustList.push(item)
                    })
                }
            })
        }
    },
    watch: {
        caseDetail(data) {
            data.caseNo && this.getAdjust()
            data.caseNo && data.isPreReport && this.getRelAdjust()
        }
    }
}
